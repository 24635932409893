import { useNavigate } from 'react-router-dom';
import { usePurchase } from '@src/hooks/usePurchase';
import { useEffect, useState } from 'react';
import { Product, ProductMetaForProject, ProductType } from '@src/models/product';
import { useGetProducts } from '@src/apis/queries';
import { css } from '@emotion/css';
import { LoadingSpinner } from '@src/components/common/LoadingSpinner';
import { getGameInfoFromLocalStorage } from '@src/utils/util';
import { Game } from '@src/models/game';

export const ProjectPage = () => {
  const navigate = useNavigate();
  const { updatePurchaseState } = usePurchase();

  const [products, setProducts] = useState<Product<ProductType, ProductMetaForProject>[]>([]);
  const [gameInfo, setGameInfo] = useState<Game | null>();
  const [selectedProduct, setSelectedProduct] = useState<Product<ProductType, ProductMetaForProject>>();
  const { data: projectProducts } = useGetProducts({ paymentMethod: 'TossPaymentPg', productType: 'Project' });

  useEffect(() => {
    setGameInfo(getGameInfoFromLocalStorage());

    if (projectProducts?.success) {
      setProducts(projectProducts.data as Product<ProductType, ProductMetaForProject>[]);
      if (products.length > 0) {
        products.map((product) => {
          if (product.meta?.projectId === gameInfo?.id) {
            setSelectedProduct(product);
            handlePurchase();
          }
        });
      }
    }
  }, [projectProducts, products, selectedProduct]);

  const handlePurchase = async () => {
    if (selectedProduct) {
      updatePurchaseState({
        selectedProduct: selectedProduct,
        postMessageType: 'purchase',
        purchaseMessageDataType: 'project',
      });
      navigate('/checkout');
    }
  };

  return (
    <>
      <div className={ShopPageStyle}>
        <LoadingSpinner />
      </div>
    </>
  );
};

const ShopPageStyle = css`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
