import { css } from '@emotion/css';
import { usePostOrderInfoCancel } from '@src/apis/mutations';
import { useGetOrderInfo, useGetTicketInfoByOrderId } from '@src/apis/queries';
import { TopNavigation } from '@src/components/TopNavigationBar';
import { Divider } from '@src/components/common/Divider';
import { OrderItem } from '@src/components/features/Order/OrderItem';
import { OrderOrdererInfo } from '@src/components/features/Order/OrderOrdererInfo';
import { PaymentSummary } from '@src/components/features/Payment/PaymentSummary';
import { OrderDetail } from '@src/models/order-info';
import { ProductMetaForTicket } from '@src/models/product';
import { Ticket } from '@src/models/ticket';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import EmptyDagoo from '@src/assets/dagoo-empty.svg';
import { getFromSessionStorage } from '@src/utils/util';
import { useQueryClient } from '@tanstack/react-query';
import { useLoading } from '@src/hooks/useLoading';

export const OrderDetailPage = () => {
  const { id } = useParams();
  const authId = getFromSessionStorage<string>('authId');
  const queryClient = useQueryClient();

  const { showLoading, hideLoading } = useLoading();

  const { data: orderData, refetch: orderRefetch } = useGetOrderInfo(id!);
  const { data: ticketInfoData, refetch: ticketRefetch } = useGetTicketInfoByOrderId(id!);
  const { mutateAsync: postOrderInfoCancel } = usePostOrderInfoCancel(id!, authId!);

  const [orderDetail, setOrderDetail] = useState<OrderDetail<'Ticket', ProductMetaForTicket>>();
  const [tickets, seTickets] = useState<Ticket[]>([]);

  useEffect(() => {
    if (orderData?.success) setOrderDetail(orderData.data as OrderDetail<'Ticket', ProductMetaForTicket>);
  }, [orderData]);

  useEffect(() => {
    if (ticketInfoData?.success) seTickets(ticketInfoData.data as Ticket[]);
  }, [ticketInfoData]);

  const handleCancel = async () => {
    const confirm = window.confirm('구매를 취소하시겠습니까?');

    if (!confirm) return;

    showLoading();
    const result = await postOrderInfoCancel();

    if (result.success) {
      setTimeout(async () => {
        // 캐시 무효화
        await queryClient.invalidateQueries({ queryKey: ['getOrderInfo', id] });
        await queryClient.invalidateQueries({ queryKey: ['getTicketInfoByOrderId', id] });

        // 강제로 리프레시
        await Promise.all([orderRefetch({ throwOnError: true }), ticketRefetch({ throwOnError: true })]);
      }, 1000);
    }

    hideLoading();
  };

  if (!orderDetail)
    return (
      <>
        <TopNavigation title='' onClickBack={() => history.back()} />
        <div className={NoneOrderPageStyle}>
          <img src={EmptyDagoo} alt='empty_dagoo' />
          <span>구매한 정보가 없어요</span>
        </div>
      </>
    );

  return (
    <>
      <TopNavigation title={orderDetail.orderProjection.createdAt.split('T')[0]} onClickBack={() => history.back()} />
      <div className={OrderDetailStyle}>
        <OrderItem
          orderer={orderDetail.userProjection}
          order={orderDetail.orderProjection}
          tickets={tickets}
          isDetail
        />
        <Divider />
        <OrderOrdererInfo order={orderDetail.orderProjection} orderer={orderDetail.userProjection} />
        <Divider />
        <PaymentSummary authId={authId} onCancel={handleCancel} orderInfo={orderDetail.orderProjection} />
      </div>
    </>
  );
};

const OrderDetailStyle = css`
  display: flex;
  flex-direction: column;
  padding: 0px 16px;
  padding-top: 48px;
  padding-bottom: 32px;
`;

const NoneOrderPageStyle = css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 16px;
  > span {
    color: var(--text-text-default, #1a1a1a);

    /* Subhead1 */
    font-size: var(--font-size-font-size-3, 18px);
    font-style: normal;
    font-weight: var(--font-weight-font-weight-extrabold, 800);
    line-height: var(--line-height-font-height-3, 26px); /* 144.444% */
  }
`;
