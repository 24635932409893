import { css } from '@emotion/css';
import { useEffect, useState } from 'react';
import { PhoneNumberInput } from './PhoneNumberInput';
import { Button } from '@src/components/common/Button';
import { usePostVerifyCodeRequest } from '@src/apis/mutations';

interface VerifyCodeRequestProps {
  phoneNumber: string;
  updatePhoneNumber: (phoneNumber: string) => void;
  goToNextComponent: () => void;
}

export const VerifyCodeRequest = ({ phoneNumber, updatePhoneNumber, goToNextComponent }: VerifyCodeRequestProps) => {
  const { mutate: postVerifyCodeRequest } = usePostVerifyCodeRequest();
  const [keyboardHeight, setKeyboardHeight] = useState(0);
  const [isEnableButton, setIsEnableButton] = useState(false);

  useEffect(() => {
    const handleViewportResize = () => {
      if (window.visualViewport) {
        const viewportHeight = window.visualViewport.height;
        const windowHeight = window.innerHeight;
        const calculatedKeyboardHeight = windowHeight - viewportHeight;

        // 키보드가 열렸다고 간주될 때만 키보드 높이를 설정
        if (calculatedKeyboardHeight > 0) {
          setKeyboardHeight(calculatedKeyboardHeight);
        } else {
          setKeyboardHeight(0);
        }
      }
    };

    window.visualViewport?.addEventListener('resize', handleViewportResize);

    return () => {
      window.visualViewport?.removeEventListener('resize', handleViewportResize);
    };
  }, []);

  const handlePhoneNumberInputChange = (combinedValue: string, isEdit: boolean) => {
    if (combinedValue && !isEdit) {
      updatePhoneNumber(combinedValue);
      setIsEnableButton(true);
    } else {
      setIsEnableButton(false);
    }
  };

  const onVerifyCodeRequest = () => {
    if (!phoneNumber) return;

    postVerifyCodeRequest({ target: phoneNumber });
    goToNextComponent();
  };

  return (
    <div className={VerifyPageStyle}>
      <div className={VerifyContentStyle}>
        <div className={VerifyTitleStyle}>
          <p>휴대폰 번호를</p>
          <p>입력해주세요</p>
        </div>
        <PhoneNumberInput onValuesChange={handlePhoneNumberInputChange} />
      </div>
      <div className={VerifyButtonStyle(keyboardHeight)}>
        <Button label='인증번호 전송' isEnable={isEnableButton} onClick={onVerifyCodeRequest} />
      </div>
    </div>
  );
};

const VerifyPageStyle = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 48px;
  padding-bottom: 65px;
`;

const VerifyContentStyle = css`
  display: flex;
  flex-direction: column;
  gap: 48px;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
`;

const VerifyTitleStyle = css`
  > p {
    color: var(--text-text-default, #1a1a1a);
    text-align: center;

    /* Hero02 */
    font-size: var(--font-size-font-size-1, 24px);
    font-style: normal;
    font-weight: var(--font-weight-font-weight-extrabold, 800);
    line-height: var(--line-height-font-height-1, 36px); /* 150% */
  }
`;
const VerifyButtonStyle = (keyboardHeight: number) => css`
  position: fixed;
  width: 100%;
  max-width: 460px;
  display: flex;
  padding: 8px 16px;
  flex-direction: column;
  align-items: flex-start;
  transition: 0.3s ease-out;
  bottom: ${keyboardHeight > 0 ? `${keyboardHeight}px` : '0'}; /* 키보드가 열렸을 때 버튼을 올리는 높이 */
`;
