import { useEffect, useState } from 'react';
import { css } from '@emotion/css';
import { ProductItem } from '@src/components/ProductItem';
import { TopNavigation } from '@src/components/TopNavigationBar';
import { BottomPurchaseBar } from '@src/components/BottomPurchaseBar';
import { useNavigate } from 'react-router-dom';
import { useGetProducts } from '@src/apis/queries';
import { Product, ProductMetaType, ProductType } from '@src/models/product';
import { usePostMessage } from '@src/hooks/usePostMessage';
import { usePurchase } from '@src/hooks/usePurchase';
import { useText } from '@src/hooks/useText';

export const ShopPage = () => {
  const navigate = useNavigate();
  const { updatePurchaseState } = usePurchase();
  const { title, actionTitle } = useText('churro');

  const [products, setProducts] = useState<Product<ProductType, ProductMetaType>[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<Product<ProductType, ProductMetaType>>();
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const { data } = useGetProducts({ paymentMethod: 'TossPaymentPg', productType: 'Point' });

  const handlePurchase = async () => {
    if (selectedProduct) {
      updatePurchaseState({
        selectedProduct: selectedProduct,
        postMessageType: 'purchase',
        purchaseMessageDataType: 'churro',
      });
      navigate('/checkout');
    } else {
      alert('상품을 선택해주세요');
    }
  };

  useEffect(() => {
    if (data?.success) setProducts(data.data as Product<ProductType, ProductMetaType>[]);
  }, [data]);

  const postMessageToParent = usePostMessage();
  const sendMessageToParent = () => {
    const closeMessage: PostMessageType<ActionMessage> = {
      type: 'action',
      data: {
        messageDataType: 'close',
        payload: { status: true },
      },
    };

    if (window.isFlutter()) postMessageToParent<ActionMessage>(closeMessage);
    else history.back();
  };

  return (
    <>
      <TopNavigation title={title} onClickBack={sendMessageToParent} />
      <div className={ShopPageStyle}>
        <div className={ProductListStyle}>
          {products.map((product, index) => (
            <ProductItem
              key={index}
              index={index}
              data={product}
              selectedIndex={selectedIndex}
              onClick={() => {
                setSelectedIndex(index);
                setSelectedProduct(product);
              }}
            />
          ))}
        </div>
        <div className={BottomInformationStyle}>
          <div className={InformationTitleStyle}>츄로 이용안내</div>
          <div className={InformationContentStyle}>
            <p>• 츄로는 직접 결제하여 충전한 리얼월드의 지불 수단입니다.</p>
            <p>• 게임 구매,크리에이터 후원등 리얼월드내의 서비스를 이용하는데 사용할 수 있습니다.</p>
            <p>• 츄로 충전 후 10일 이내 사용하지 않은 츄로만 결제 취소가 가능합니다.</p>
            <p>• 츄로 충전 후 보너스 츄로만 사용한 경우 결제 취소가 불가능합니다.</p>
            <p>• 법정대리인의 동의 없는 미성년자의 결제는 취소될 수 있습니다.</p>
            <p>• 충전된 츄로의 이용기간과 환불가능기간은 결제시점으로부터 1년 이내로 제한됩니다.</p>
            <p>• 자세한 정보는 홈페이지의 이용약관을 참고해주세요.</p>
          </div>
        </div>
      </div>
      <BottomPurchaseBar
        isSelected={selectedIndex >= 0}
        selectedProduct={selectedProduct}
        onClick={handlePurchase}
        actionTitle={actionTitle}
      />
    </>
  );
};

const ShopPageStyle = css`
  width: 100%;
  align-items: center;
  padding-top: 48px;
  padding-bottom: 65px;
`;

const ProductListStyle = css`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 8px;
  padding: 0px 16px;
  padding-bottom: 40px;
`;
const BottomInformationStyle = css`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: var(--light-gray-100, #f2f2f2);
`;

const InformationTitleStyle = css`
  width: 100%;
  padding: 16px;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  font-size: 14px;
`;

const InformationContentStyle = css`
  display: flex;
  width: 100%;
  padding: 0px 16px 16px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  word-break: keep-all;

  color: var(--light-gray-800, #515253);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
`;
