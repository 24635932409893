import { css } from '@emotion/css';
import BackArrowIcon from '@src/assets/back-arrow.svg';
import CloseIcon from '@src/assets/close.svg';

interface NavigationProps {
  title: string;
  isClose?: boolean;
  onClickClose?: () => void;
  onClickBack?: () => void;
}

export const TopNavigation = (props: NavigationProps) => {
  return (
    <div className={TopNavigationStyle}>
      <div className={TopNavigationItemStyle}>
        {props.isClose && <img src={CloseIcon} alt='close' style={{ padding: '8px' }} onClick={props.onClickClose} />}
        {!props.isClose && (
          <img src={BackArrowIcon} alt='back_arrow' style={{ padding: '8px' }} onClick={props.onClickBack} />
        )}
      </div>
      <div className={TopNavigationItemStyle}>
        <div className={TopNavigationTitleStyle}>{props.title}</div>
      </div>
      <div className={TopNavigationItemStyle}>{/* 빈 공간 */}</div>
    </div>
  );
};

const TopNavigationStyle = css`
  z-index: 999;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 460px;
  height: 48px;
  padding: 0px 8px;
  background-color: white;
`;

const TopNavigationTitleStyle = css`
  width: 152px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  text-align: center;
  color: black;
`;

const TopNavigationItemStyle = css`
  flex: 1;
`;
