// 메시지 타입을 정의합니다.

import { OrderState } from '@src/models/order-info';
import { TicketStatus } from '@src/models/ticket';

interface PurchaseText {
  title: string;
  actionTitle: string;
  successMessage: string;
  failMessage: string;
  failRoute: string;
}

interface DonationText {
  profileImage: string;
  userName: string;
  churro: string;
}

type PurchaseTextKeys = {
  [key in PurchaseMessageDataType]: PurchaseText;
};

type OrderStatusTextKeys = {
  [key in OrderState]: string;
};

type TicketStatusTextKeys = {
  [key in TicketStatus]: string;
};

export const OrderStatusTexts: OrderStatusTextKeys = {
  Created: '주문요청',
  Paid: '결제완료',
  Refunded: '환불완료',
  Failed: '결제실패',
  Canceled: '취소완료',
};

export const TicketStatusTexts: TicketStatusTextKeys = {
  Created: '사용전',
  Used: '사용완료',
  Refunded: '환불',
  Removed: '취소',
};

export const PurchaseTexts: PurchaseTextKeys = {
  churro: {
    title: '츄로 구매하기',
    actionTitle: '구매하기',
    successMessage: '츄로 구매가 완료되었어요!',
    failMessage: '츄로 구매에 실패했어요.',
    failRoute: '/shop',
  },
  donation: {
    title: '크리에이터 후원하기',
    actionTitle: '후원하기',
    successMessage: '크리에이터 후원이 완료되었어요!',
    failMessage: '크리에이터 후원에 실패했어요.',
    failRoute: '/donation',
  },
  project: {
    title: '게임 구매하기',
    actionTitle: '구매하기',
    successMessage: '게임 구매가 완료되었어요!',
    failMessage: '게임 구매에 실패했어요.',
    failRoute: '/project',
  },
  ticket: {
    title: '티켓 구매하기',
    actionTitle: '구매하기',
    successMessage: '티켓 구매가 완료되었어요!',
    failMessage: '티켓 구매에 실패했어요.',
    failRoute: '/ticket',
  },
  etc: {
    title: '구매하기',
    actionTitle: '구매하기',
    successMessage: '구매가 완료되었어요!',
    failMessage: '구매에 실패했어요.',
    failRoute: '/shop',
  },
};

export const MockDonationText: DonationText[] = [
  {
    profileImage: '/mock-profile/1.svg',
    userName: '게꿀땃쥐',
    churro: '300',
  },
  {
    profileImage: '/mock-profile/2.svg',
    userName: '멈_멈_멈',
    churro: '1,000',
  },
  {
    profileImage: '/mock-profile/3.svg',
    userName: '꼬질대마왕',
    churro: '2,000',
  },
  {
    profileImage: '/mock-profile/4.svg',
    userName: '벤치최대100g',
    churro: '200',
  },
  {
    profileImage: '/mock-profile/5.svg',
    userName: '갬성충만',
    churro: '500',
  },
  {
    profileImage: '/mock-profile/6.svg',
    userName: 'STAY봄',
    churro: '10,000',
  },
  {
    profileImage: '/mock-profile/7.svg',
    userName: '말티즈갱갱',
    churro: '100',
  },
  {
    profileImage: '/mock-profile/8.svg',
    userName: '붐뱁크랩',
    churro: '500',
  },
  {
    profileImage: '/mock-profile/9.svg',
    userName: '냥냥고롱뱀',
    churro: '5,000',
  },
  {
    profileImage: '/mock-profile/10.svg',
    userName: '리얼월드찐팬',
    churro: '2,000',
  },
];
