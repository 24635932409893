import { css } from '@emotion/css';
import { useGetProducts } from '@src/apis/queries';
import { Button } from '@src/components/common/Button';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const HomePage = () => {
  const navigate = useNavigate();
  // const sendMessage = (window: Window, { type, data }: PostMessageType<AuthMessage>, targetOrigin?: string) => {
  //   window.postMessage({ type, data }, targetOrigin || '*');
  // };
  const { data } = useGetProducts({ paymentMethod: 'TossPaymentPg', productType: 'Project' });

  useEffect(() => {
    if (data?.success) {
      console.log(data);
    }
  }, []);

  // const handleClickWindowOpen = () => {
  //   const popupWindow = window.open(`http://localhost:5173/shop`);

  //   if (popupWindow) {
  //     setTimeout(() => {
  //       sendMessage(popupWindow, {
  //         type: 'auth',
  //         data: {
  //           messageDataType: 'refresh',
  //           payload: {
  //             status: true,
  //             accessToken: 'accessToken',
  //             refreshToken: 'refreshToken',
  //           },
  //         },
  //       });
  //     }, 1000);
  //   }
  // };

  return (
    <div className={HomePageStyle}>
      <Button onClick={() => navigate('/ticket')} label='티켓샵' isPrimary={false} />
      <Button onClick={() => navigate('/shop')} label='츄로샵' isPrimary={false} />
      <Button onClick={() => navigate('/donation')} label='후원샵' isPrimary={false} />

      {/* <div className={ButtonStyle} onClick={handleClickWindowOpen}>
        테스트
      </div> */}
    </div>
  );
};

const HomePageStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  gap: 36px;
  padding: 16px;
`;
