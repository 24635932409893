import { useState } from 'react';
import { css } from '@emotion/css';
import { usePostOrderCallback } from '@src/apis/mutations';
import { TopNavigation } from '@src/components/TopNavigationBar';
import { RequestOrderCallback } from '@src/models/request';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import DagooSuccess from '@src/assets/dagoo-success.svg';
import { usePostMessage } from '@src/hooks/usePostMessage';
import { LoadingSpinner } from '@src/components/common/LoadingSpinner';
import { Img } from 'react-image';
import { useText } from '@src/hooks/useText';
import { getFromSessionStorage } from '@src/utils/util';
import { PurchaseState } from '@src/models/purchase';
import { VerifySuccess } from '@src/models/verify';

export const SuccessPage = () => {
  const navigate = useNavigate();
  const purchaseState: PurchaseState = getFromSessionStorage<PurchaseState>('purchaseState')!;
  const verifySuccess: VerifySuccess = getFromSessionStorage<VerifySuccess>('verifySuccess')!;
  const { successMessage } = useText(purchaseState.purchaseMessageDataType);

  const isTicket = purchaseState.purchaseMessageDataType === 'ticket';

  const [isSuccess, setIsSuccess] = useState(false);
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get('orderId')!;
  const amount = Number(searchParams.get('amount'))!;
  const paymentKey = searchParams.get('paymentKey')!;

  const { mutateAsync: postOrderCallback } = usePostOrderCallback();

  useEffect(() => {
    const requestData: RequestOrderCallback = {
      orderId: orderId,
      amount: amount,
      paymentKey: paymentKey,
    };

    async function confirm() {
      try {
        if (requestData.orderId && requestData.amount && requestData.paymentKey) {
          const result = await postOrderCallback(requestData);
          setIsSuccess(result.success);
          localStorage.setItem('isPurchasedTicket', 'true');

          if (!result.success) {
            navigate(`/fail?message=${result.message}&isTicket=${isTicket}`, { replace: true });
            return;
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
    confirm();
  }, []);

  const postMessageToParent = usePostMessage(import.meta.env.VITE_POST_MESSAGE_TARGET_URL);

  const checkOrder = () => {
    if (isTicket) {
      navigate(`/orders?authId=${verifySuccess.id}&orderId=${orderId}`, { replace: true });
    } else {
      sendMessageToParent();
    }
  };

  const sendMessageToParent = () => {
    const message: PostMessageType<PurchaseMessage> = {
      type: purchaseState.postMessageType,
      data: {
        messageDataType: purchaseState.purchaseMessageDataType,
        payload: { status: true },
      },
    };

    postMessageToParent<PurchaseMessage>(message);

    window.close();
  };

  if (!isSuccess)
    return (
      <div className={loadingContainerStyle}>
        <LoadingSpinner />
      </div>
    );

  return (
    <>
      {!isTicket && <TopNavigation title='' isClose={true} onClickClose={sendMessageToParent} />}
      <div className={SuccessPageStyle}>
        <Img src={DagooSuccess} alt='dagoo' />
        <span className={TitleSpanStyle}>
          <p className={MainTitleStyle}>{successMessage}</p>
          <p className={SubTitleStyle}>결제가 정상적으로 처리되었습니다.</p>
        </span>
        <div className={ButtonStyle} onClick={checkOrder}>
          {isTicket ? '티켓 확인하기' : '이전창으로 돌아가기'}
        </div>
      </div>
    </>
  );
};

const SuccessPageStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  gap: 8px;
  padding: 0px 16px;
  padding-top: 48px;
  padding-bottom: 65px;
`;

const TitleSpanStyle = css`
  display: flex;
  padding-bottom: 16px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const MainTitleStyle = css`
  color: var(--black, #191919);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 26px;
`;

const SubTitleStyle = css`
  color: var(--gray-500, #a7a8a9);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.1px;
`;

const ButtonStyle = css`
  display: flex;
  width: 184px;
  height: 46px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: var(--light-primary-500, #7800ff);

  color: var(--text-text-on-color, #fff);
  text-align: center;
  font-family: var(--font-family-Pretendard, Pretendard);
  font-size: var(--font-size-font-size-4, 14px);
  font-style: normal;
  font-weight: var(--font-weight-font-weight-bold, 600);
  line-height: var(--line-height-font-height-4, 20px); /* 142.857% */
`;

const loadingContainerStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  body {
    width: 100%;
    height: 100vh;
  }
`;
