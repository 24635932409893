import { createContext, useState } from 'react';
import { Product, ProductMetaType, ProductType } from '@src/models/product';
import { PurchaseState } from '@src/models/purchase';

interface PurchaseContextType {
  purchaseState: PurchaseState;
  updatePurchaseState: (purchaseState: Partial<PurchaseState>) => void;
}

const initialState: PurchaseState = {
  orderName: '',
  postMessageType: 'purchase',
  purchaseMessageDataType: 'etc',
  selectedProduct: {} as Product<ProductType, ProductMetaType>,
  selectedTicketProducts: [],
};

const PurchaseContext = createContext<PurchaseContextType | undefined>(undefined);

const PurchaseProvider = ({ children }: { children: React.ReactNode }) => {
  const [purchaseState, setPurchaseState] = useState<PurchaseState>(initialState);

  const updatePurchaseState = (purchaseState: Partial<PurchaseState>) => {
    setPurchaseState((prev) => ({ ...prev, ...purchaseState }));
  };

  return <PurchaseContext.Provider value={{ purchaseState, updatePurchaseState }}>{children}</PurchaseContext.Provider>;
};

export { PurchaseProvider, PurchaseContext };
