import { css, keyframes } from '@emotion/css';
import { useLoading } from '@src/hooks/useLoading';

export function LoadingSpinner() {
  const { isLoading } = useLoading();

  if (!isLoading) return null;

  return (
    <div className={LoadingSpinnerStyle}>
      <svg className={loadingSpinnerWrapStyle} viewBox='0 0 50 50'>
        <circle className={loadingSpinnerCircleStyle} cx='25' cy='25' r='20' fill='none' strokeWidth='4' />
      </svg>
    </div>
  );
}

const rotate = keyframes({
  '100%': {
    transform: 'rotate(360deg)',
  },
});
const dash = keyframes({
  '0%': {
    strokeDasharray: '1, 150',
    strokeDashoffset: '0',
  },
  '50%': {
    strokeDasharray: '90, 150',
    strokeDashoffset: '-35',
  },
  '100%': {
    strokeDasharray: '90, 150',
    strokeDashoffset: '-124',
  },
});

const LoadingSpinnerStyle = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;
const loadingSpinnerWrapStyle = css`
  animation: ${rotate} 2s linear infinite;
  width: 50px;
  height: 50px;
`;

const loadingSpinnerCircleStyle = css`
  stroke: #7800ff;
  stroke-linecap: round;
  animation: ${dash} 1.5s ease-in-out infinite;
`;
