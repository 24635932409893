import { css } from '@emotion/css';
import { TicketStatusTexts } from '@src/constants/text';
import { TicketStatus } from '@src/models/ticket';

interface TicketStatusLabelProps {
  ticketStatus?: TicketStatus;
}

export const TicketStatusLabel = ({ ticketStatus }: TicketStatusLabelProps) => {
  return (
    <div className={TicketItemStatusStyle(ticketStatus ?? 'Created')}>
      {TicketStatusTexts[ticketStatus ?? 'Created']}
    </div>
  );
};

const TicketItemStatusStyle = (ticketStatus: TicketStatus) => css`
  display: flex;
  height: 24px;
  align-items: center;
  border-radius: var(--tag-rectangle, 4px);

  color: ${ticketStatus === 'Created' ? '#7800ff' : '#555555'};

  /* Subhead3 */
  font-size: var(--font-size-font-size-4, 14px);
  font-style: normal;
  font-weight: var(--font-weight-font-weight-bold, 700);
  line-height: var(--line-height-font-height-4, 20px); /* 142.857% */
`;
