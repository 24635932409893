import { css } from '@emotion/css';
import { TopNavigation } from '@src/components/TopNavigationBar';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { usePostMessage } from '@src/hooks/usePostMessage';
import { useText } from '@src/hooks/useText';
import { getFromSessionStorage } from '@src/utils/util';
import DagooFail from '@src/assets/dagoo-fail.svg';
import { PurchaseState } from '@src/models/purchase';

export const FailPage = () => {
  const navigate = useNavigate();
  const purchaseState: PurchaseState = getFromSessionStorage<PurchaseState>('purchaseState')!;
  const { failMessage, failRoute } = useText(purchaseState.purchaseMessageDataType);

  const [searchParams] = useSearchParams();
  const message = searchParams.get('message');
  const isTicket = searchParams.get('isTicket') === 'true';

  const postMessageToParent = usePostMessage(import.meta.env.VITE_POST_MESSAGE_TARGET_URL);
  const sendMessageToParent = () => {
    const message: PostMessageType<PurchaseMessage> = {
      type: purchaseState.postMessageType,
      data: {
        messageDataType: purchaseState.purchaseMessageDataType,
        payload: { status: false },
      },
    };

    postMessageToParent<PurchaseMessage>(message);
    navigate(failRoute, { replace: true });
  };

  const sendInquiryMessageToParent = () => {
    const message: PostMessageType<ActionMessage> = {
      type: 'action',
      data: {
        messageDataType: 'inquiry',
        payload: { status: true },
      },
    };

    postMessageToParent<ActionMessage>(message);
  };

  return (
    <>
      <TopNavigation title='' onClickBack={sendMessageToParent} />
      <div className={SuccessPageStyle}>
        <img src={DagooFail} alt='dagoo' />
        <span className={TitleSpanStyle}>
          <p className={MainTitleStyle}>{failMessage}</p>
          <p className={SubTitleStyle}>{message}</p>
        </span>
        <div className={ButtonGroupStyle}>
          {!isTicket && (
            <div className={InquiryButtonStyle} onClick={sendInquiryMessageToParent}>
              1:1 문의하기
            </div>
          )}
          <div className={RetryButtonStyle} onClick={sendMessageToParent}>
            다시 시도하기
          </div>
        </div>
      </div>
    </>
  );
};

const SuccessPageStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  gap: 8px;
  padding: 0px 16px;
  padding-top: 48px;
  padding-bottom: 65px;
`;

const TitleSpanStyle = css`
  display: flex;
  padding-bottom: 16px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const MainTitleStyle = css`
  color: var(--black, #191919);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 26px;
`;

const SubTitleStyle = css`
  color: var(--gray-500, #a7a8a9);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.1px;
`;

const ButtonGroupStyle = css`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
`;

const InquiryButtonStyle = css`
  display: flex;
  width: 120px;
  height: 46px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: var(--light-gray-100, #f2f2f2);

  color: var(--text-text-description, #555);
  text-align: center;

  /* Subhead2 */
  font-family: var(--font-family-Pretendard, Pretendard);
  font-size: var(--font-size-font-size-3, 14px);
  font-style: normal;
  font-weight: var(--font-weight-font-weight-bold, 600);
  line-height: var(--line-height-font-height-3, 22px); /* 137.5% */
`;

const RetryButtonStyle = css`
  display: flex;
  width: 120px;
  height: 46px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: var(--light-primary-500, #7800ff);

  color: var(--text-text-on-color, #fff);
  text-align: center;

  /* Subhead3 */
  font-family: var(--font-family-Pretendard, Pretendard);
  font-size: var(--font-size-font-size-4, 14px);
  font-style: normal;
  font-weight: var(--font-weight-font-weight-bold, 600);
  line-height: var(--line-height-font-height-4, 20px); /* 142.857% */
`;
