import { css } from '@emotion/css';
import { OrderInfo, OrdererInfo } from '@src/models/order-info';
import { Product, ProductMetaForTicket } from '@src/models/product';
import { OrderStateLabel } from './OrderStateLabel';
import { OrderTicketItem } from './OrderTicketItem';
import { Ticket } from '@src/models/ticket';
import { Img } from 'react-image';
import EmptyImage from '@src/assets/empty.png';
interface OrderItemProps {
  orderer?: OrdererInfo;
  order: OrderInfo<'Ticket', ProductMetaForTicket>;
  onClick?: () => void;
  isDetail?: boolean;
  padding?: string;
  tickets?: Ticket[];
}

export const OrderItem = ({ orderer, order, onClick, isDetail = false, padding = '0px', tickets }: OrderItemProps) => {
  const products = order.productList as Product<'Ticket', ProductMetaForTicket>[];
  const groupImage = products.length > 0 && products[0].meta?.groupImageUrl;
  const orderItemImage = groupImage ? groupImage : EmptyImage;

  return (
    <div className={OrderItemStyle(padding)} onClick={onClick}>
      <div className={OrderItemHeaderStyle}>
        <div className={OrderItemStatusInfoStyle}>
          <OrderStateLabel orderState={order.orderState} />
          <div className={OrderItemStatusInfoDotStyle}>•</div>
          <div className={OrderItemOrderNumberStyle}>구매번호 : {order.orderNumber} </div>
        </div>
        <div className={OrderItemImageAndNameStyle}>
          <Img src={orderItemImage} alt='구매 상품 제목' />
          <div className={OrderItemNameStyle}>
            <p>{order.orderName}</p>
          </div>
        </div>
      </div>
      {/* 주문 목록에서 사용하는 컴포넌트 */}
      {!isDetail && (
        <div className={OrderItemOptionStyle}>
          {order.productList?.map((product, index) => (
            <div key={index} className={OrderItemOptionItemStyle}>
              <div>{product.name}</div>
              <div className={OrderItemOptionPriceStyle}>
                <span>수량 {product.quantity}개</span>
                <p>ㅣ</p>
                <span>{product.price.toLocaleString()}원</span>
              </div>
            </div>
          ))}
        </div>
      )}
      {/* 주문 상세 화면에서 쓰는 컴포넌트 */}
      {isDetail && tickets && (
        <div className={OrderDetailItemGroupStyle}>
          <OrderTicketItem orderer={orderer} order={order} products={products} tickets={tickets} />
        </div>
      )}
    </div>
  );
};

const OrderItemStyle = (padding: string) => css`
  display: flex;
  width: 100%;
  flex-direction: column;
  border-radius: 8px;
  gap: 16px;
  padding: ${padding};

  border-radius: 16px;
  background: #ffffff;
`;

const OrderItemHeaderStyle = css`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
const OrderItemStatusInfoStyle = css`
  display: flex;
  align-items: center;
  gap: 4px;
  align-self: stretch;
`;
const OrderItemStatusInfoDotStyle = css`
  color: var(--border-border-default, #e8e8e8);

  /* Body2 */
  font-family: var(--font-family-Pretendard, Pretendard);
  font-size: var(--font-size-font-size-5, 14px);
  font-style: normal;
  font-weight: var(--font-weight-font-weight-regular, 400);
  line-height: var(--line-height-font-height-5, 20px); /* 142.857% */
  letter-spacing: -0.1px;
`;

const OrderItemOrderNumberStyle = css`
  color: var(--text-text-on-transparent-gray, #555);

  /* Body2 */
  font-family: var(--font-family-Pretendard, Pretendard);
  font-size: var(--font-size-font-size-5, 14px);
  font-style: normal;
  font-weight: var(--font-weight-font-weight-regular, 400);
  line-height: var(--line-height-font-height-5, 20px); /* 142.857% */
  letter-spacing: -0.1px;
`;

const OrderItemImageAndNameStyle = css`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  gap: 16px;

  > img {
    object-fit: cover;
    border-radius: 11.429px;
    background: lightgray 50% / cover no-repeat;
    width: 80px;
    height: 80px;
  }
`;

const OrderItemNameStyle = css`
  > p {
    overflow: hidden;
    color: var(--text-text-default, #1a1a1a);
    white-space: pre-wrap;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    word-break: keep-all;

    /* Subhead2 */
    font-size: var(--font-size-font-size-4, 16px);
    font-style: normal;
    font-weight: var(--font-weight-font-weight-bold, 700);
    line-height: var(--line-height-font-height-4, 22px); /* 137.5% */
  }
`;

const OrderItemOptionStyle = css`
  display: flex;
  padding: 12px 16px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 16px;

  border-radius: 16px;
  background: var(--surface-surface-card, #f5f5f5);
`;

const OrderItemOptionItemStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;

  overflow: hidden;
  color: var(--text-text-default, #1a1a1a);
  text-overflow: ellipsis;
  /* Body2 */
  font-size: var(--font-size-font-size-5, 14px);
  font-style: normal;
  font-weight: var(--font-weight-font-weight-regular, 400);
  line-height: var(--line-height-font-height-5, 20px); /* 142.857% */
  letter-spacing: -0.1px;
`;

const OrderItemOptionPriceStyle = css`
  display: flex;

  > span {
    color: var(--text-text-caption, #8f8f8f);
    text-overflow: ellipsis;

    /* Caption1 */
    font-size: var(--font-size-font-size-6, 12px);
    font-style: normal;
    font-weight: var(--font-weight-font-weight-medium, 500);
    line-height: var(--line-height-font-height-6, 16px); /* 133.333% */
  }

  > p {
    overflow: hidden;
    color: var(--border-border-default, #e8e8e8);
    text-overflow: ellipsis;

    /* Caption1 */
    font-family: var(--font-family-Pretendard, Pretendard);
    font-size: var(--font-size-font-size-6, 12px);
    font-style: normal;
    font-weight: var(--font-weight-font-weight-medium, 500);
    line-height: var(--line-height-font-height-6, 16px); /* 133.333% */
  }
`;

const OrderDetailItemGroupStyle = css`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
