import { Creator } from '@src/models/creator';
import { Game } from '@src/models/game';
import { ProjectLite } from '@src/models/project';
import { UserInfo } from '@src/models/user-info';
import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

export const getUserInfoFromLocalStorage = (): UserInfo | null => {
  const userInfoString = localStorage.getItem('userInfo');
  if (!userInfoString) return null;
  try {
    const userInfo = JSON.parse(userInfoString) as UserInfo;
    return userInfo;
  } catch (error) {
    console.error('사용자 정보 파싱 중 오류 발생:', error);
    return null;
  }
};

export const getProjectInfoFromLocalStorage = (): ProjectLite | null => {
  const projectInfoString = localStorage.getItem('projectInfo');
  if (!projectInfoString) return null;
  try {
    const projectInfo = JSON.parse(projectInfoString) as ProjectLite;
    return projectInfo;
  } catch (error) {
    console.error('프로젝트 정보 파싱 중 오류 발생:', error);
    return null;
  }
};

export const getGameInfoFromLocalStorage = (): Game | null => {
  const gameInfoString = localStorage.getItem('gameInfo');
  if (!gameInfoString) return null;
  try {
    const gameInfo = JSON.parse(gameInfoString) as Game;
    return gameInfo;
  } catch (error) {
    console.error('게임 정보 파싱 중 오류 발생:', error);
    return null;
  }
};

export const getCreatorInfoFromLocalStorage = (): Creator | null => {
  const creatorInfoString = localStorage.getItem('creatorInfo');
  if (!creatorInfoString) return null;
  try {
    const creatorInfo = JSON.parse(creatorInfoString) as Creator;
    return creatorInfo;
  } catch (error) {
    console.error('크리에이터 정보 파싱 중 오류 발생:', error);
    return null;
  }
};

type PhoneNumberFormatOptions = {
  countryCode: string;
  replaceWith: string;
  alreadyFormattedPattern?: string;
};

const defaultOptions: PhoneNumberFormatOptions = {
  countryCode: '+82',
  replaceWith: '0',
  alreadyFormattedPattern: '^010\\d{8}$',
};

export const convertPhoneNumber = (
  number: string,
  { countryCode, replaceWith, alreadyFormattedPattern }: PhoneNumberFormatOptions = defaultOptions,
): string => {
  const strippedNumber = number.replace(/[^\d+]/g, ''); // +를 포함한 모든 비숫자 문자 제거

  const alreadyFormattedRegex = alreadyFormattedPattern ? new RegExp(alreadyFormattedPattern) : null;

  if (alreadyFormattedRegex && alreadyFormattedRegex.test(strippedNumber)) return strippedNumber;

  let formattedNumber = strippedNumber;

  // +82로 시작하는 경우, +82를 0으로 대체
  if (formattedNumber.startsWith(countryCode)) {
    formattedNumber = replaceWith + formattedNumber.substring(countryCode.length);
  }

  return formattedNumber;
};

export const saveToSessionStorage = <T>(key: string, value: T): void => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

export const getFromSessionStorage = <T>(key: string): T | null => {
  const item = sessionStorage.getItem(key);
  return item ? (JSON.parse(item) as T) : null;
};

export const isJson = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
};

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
