import { Drawer, DrawerContent, DrawerTitle } from '@src/components/ui/drawer';
import { useNavigate } from 'react-router-dom';
import { Fragment, useEffect, useState } from 'react';
import { css } from '@emotion/css';
import MinusIcon from '@src/assets/icon-minus.svg';
import MinusDisabledIcon from '@src/assets/icon-minus-disable.svg';
import PlusIcon from '@src/assets/icon-plus.svg';
import { Product, ProductMetaForTicket } from '@src/models/product';
import { useVerification } from '@src/hooks/useVerification';
import { usePurchase } from '@src/hooks/usePurchase';
import { TicketPurchaseProduct } from '@src/models/purchase';
interface TicketGroupInfo {
  groupId?: string;
  groupName?: string;
  groupImageUrl?: string;
}

interface BottomDrawerProps {
  isOpen: boolean;
  ticketProducts: Product<'Ticket', ProductMetaForTicket>[];
  updateIsBottomDrawerOpen: (isOpen: boolean) => void;
}

export const TicketBottomDrawer = ({ isOpen = false, updateIsBottomDrawerOpen, ticketProducts }: BottomDrawerProps) => {
  const navigate = useNavigate();
  const { isVerified } = useVerification();
  const { updatePurchaseState } = usePurchase();

  const [quantities, setQuantities] = useState<{ [key: string]: number }>({});
  const [selectedTicketProduct, setSelectedTicketProduct] = useState<TicketPurchaseProduct[]>([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isPurchaseVisible, setPurchaseVisible] = useState(false);

  const getTicketGroupInfo = (products: typeof ticketProducts): TicketGroupInfo => {
    if (products.length === 0) return {};

    const { groupId, groupName, groupImageUrl } = products[0].meta || {};
    return {
      groupId: groupId ?? '',
      groupName: groupName ?? '',
      groupImageUrl: groupImageUrl ?? '',
    };
  };

  const ticketGroupInfo = getTicketGroupInfo(ticketProducts);

  const handleQuantityChange = (productId: string, amount: number) => {
    setQuantities((prevQuantities) => {
      const currentQuantity = prevQuantities[productId] || 0;
      const newQuantity = currentQuantity + amount;

      if (newQuantity <= 0) {
        const updatedQuantities = { ...prevQuantities };
        delete updatedQuantities[productId];
        return updatedQuantities;
      }

      return {
        ...prevQuantities,
        [productId]: newQuantity,
      };
    });
  };

  useEffect(() => {
    const updatedProducts = Object.entries(quantities).map(([productId, quantity]) => ({ productId, quantity }));

    setSelectedTicketProduct(updatedProducts);
  }, [quantities]);

  const onPurchase = () => {
    updatePurchaseState({
      orderName: ticketGroupInfo.groupName,
      selectedTicketProducts: selectedTicketProduct,
      postMessageType: 'purchase',
      purchaseMessageDataType: 'ticket',
    });

    if (isVerified) navigate('/checkout');
    else navigate('/verify');
  };

  useEffect(() => {
    // quantities 상태가 변경될 때마다 totalPrice를 재계산
    const newTotalPrice =
      ticketProducts?.reduce((total, product) => {
        return total + product.price * (quantities[product.id] || 0);
      }, 0) || 0;

    setTotalPrice(newTotalPrice);

    const totalQuantity = Object.values(quantities).reduce((acc, quantity) => acc + quantity, 0);
    setPurchaseVisible(totalQuantity > 0);
  }, [quantities, ticketProducts]);

  return (
    <Drawer open={isOpen} onOpenChange={updateIsBottomDrawerOpen}>
      <DrawerTitle className='opacity-0 absolute-0'>티켓 옵션 목록</DrawerTitle>
      <DrawerContent>
        <div className={ProductOptionStyle}>
          <div className={ProductOptionContentStyle}>
            <div className={ProductOptionTitleSectionStyle}>
              <img src={ticketGroupInfo.groupImageUrl} alt='ticket' />
              <div className={ProductOptionTitleStyle}>
                <p>{ticketGroupInfo.groupName}</p>
              </div>
            </div>
            <div className={ProductOptionListStyle}>
              {ticketProducts?.map((product, idx) => (
                <Fragment key={idx}>
                  <div className={ProductOptionListItemStyle}>
                    <div className={ProductOptionListItemName}>
                      <p>{product.name}</p>
                      <b>{product.price.toLocaleString()}원</b>
                    </div>
                    <div className={ProductOptionListItemQuantity}>
                      <img
                        src={quantities[product.id] > 0 ? MinusIcon : MinusDisabledIcon}
                        alt='minus'
                        onClick={() => {
                          handleQuantityChange(product.id, -1);
                        }}
                      />
                      <div className={ProductOptionListItemQuantityNumber}>{quantities[product.id] || 0}</div>
                      <img
                        src={PlusIcon}
                        alt='plus'
                        onClick={() => {
                          handleQuantityChange(product.id, 1);
                        }}
                      />
                    </div>
                  </div>
                  <div className={ProductOptionListItemDivider} />
                </Fragment>
              ))}
            </div>
          </div>

          <div className={ProductOptionPurchaseStyle}>
            <div className={ProductOptionPurchaseTotalPriceStyle}>
              <p>총 금액</p>
              <p>{totalPrice.toLocaleString()}원</p>
            </div>
            {isPurchaseVisible && (
              <div className={ProductOptionPurchaseButtonStyle} onClick={onPurchase}>
                인증 후 구매하기
              </div>
            )}
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  );
};

const ProductOptionStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 550px;
  background-color: white;
`;

const ProductOptionContentStyle = css`
  width: 100%;
  padding: 0px 16px;
`;

const ProductOptionTitleSectionStyle = css`
  display: flex;
  height: 88px;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  align-self: stretch;

  > img {
    width: 56px;
    height: 56px;
    border-radius: 8px;
    object-fit: cover;
  }
`;

const ProductOptionTitleStyle = css`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  > p {
    width: 100%;
    white-space: pre-wrap;
    color: var(--text-text-default, #1a1a1a);

    /* Subhead3 */
    font-size: var(--font-size-font-size-5, 14px);
    font-style: normal;
    font-weight: var(--font-weight-font-weight-bold, 700);
    line-height: var(--line-height-font-height-5, 20px); /* 142.857% */
  }
`;

const ProductOptionListStyle = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  height: 348px;
  overflow-y: auto;
  background-color: #ffffff;
`;

const ProductOptionListItemStyle = css`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
  width: 100%;
`;

const ProductOptionListItemName = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;

  > p {
    overflow: hidden;
    color: var(--text-text-description, #555);
    text-overflow: ellipsis;

    /* Body1 */
    font-family: var(--font-family-Pretendard, Pretendard);
    font-size: var(--font-size-font-size-4, 16px);
    font-style: normal;
    font-weight: var(--font-weight-font-weight-regular, 400);
    line-height: var(--line-height-font-height-4, 22px); /* 137.5% */
    letter-spacing: -0.1px;
  }
`;

const ProductOptionListItemDivider = css`
  display: flex;
  height: 1px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;
  align-self: stretch;
  background: var(--background-section-on-card, #f5f5f5);
`;

const ProductOptionListItemQuantity = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 16px;
  width: 114px;
  height: 40px;
  border-radius: 16px;
  background: var(--background-section-on-card, #f5f5f5);
  flex: 0 0 1;

  > img {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
  }
`;

const ProductOptionListItemQuantityNumber = css`
  display: flex;
  width: 34px;
  padding: 4px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: var(--in-box, 8px);
  background: var(--background-background, #fff);

  color: var(--text-text-default, #1a1a1a);
  text-align: center;

  /* Body1 */
  font-family: var(--font-family-Pretendard, Pretendard);
  font-size: var(--font-size-font-size-4, 16px);
  font-style: normal;
  font-weight: var(--font-weight-font-weight-regular, 400);
  line-height: var(--line-height-font-height-4, 22px); /* 137.5% */
  letter-spacing: -0.1px;
`;

const ProductOptionPurchaseStyle = css`
  z-index: 1000;
  width: 100%;
  height: 122px;
  min-height: 122px;
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-top: 1px solid var(--border-border-default, #e8e8e8);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.12);
`;

const ProductOptionPurchaseTotalPriceStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  color: var(--text-text-default, #1a1a1a);

  /* Subhead1 */
  font-size: var(--font-size-font-size-3, 18px);
  font-style: normal;
  font-weight: var(--font-weight-font-weight-extrabold, 800);
  line-height: var(--line-height-font-height-3, 26px); /* 144.444% */
`;

const ProductOptionPurchaseButtonStyle = css`
  display: flex;
  height: 48px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: var(--button-M-48, 16px);
  background: var(--surface-surface-primary, #7800ff);

  color: var(--text-text-on-color, #fff);
  text-align: center;

  /* Subhead2 */
  font-size: var(--font-size-font-size-4, 16px);
  font-style: normal;
  font-weight: var(--font-weight-font-weight-bold, 700);
  line-height: var(--line-height-font-height-4, 22px); /* 137.5% */
`;
