import { css } from '@emotion/css';
import ChevronIcon from '@src/assets/icon-chevron-up.svg';

interface ChevronProps {
  isExpanded: boolean;
  toggleChevron: () => void;
}

export const Chevron = ({ isExpanded, toggleChevron }: ChevronProps) => {
  return (
    <div className={chevronContainer} onClick={toggleChevron}>
      <img src={ChevronIcon} alt='chevron' className={`${chevronStyle} ${isExpanded ? 'expanded' : ''}`} />
    </div>
  );
};

const chevronContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const chevronStyle = css`
  transition: transform 0.3s ease; /* 부드러운 회전 애니메이션 */
  transform: rotate(180deg); /* 기본 방향: 위쪽 */

  &.expanded {
    transform: rotate(0deg); /* 아래쪽으로 회전 */
  }
`;
