import { Creator } from '@src/models/creator';
import { Game } from '@src/models/game';
import { ProjectLite } from '@src/models/project';

const saveInfoToLocalStorage = <T>(key: string, args: string) => {
  const stringWithoutNewLines = args.replace(/\n/g, '');
  const info: T = JSON.parse(stringWithoutNewLines);
  if (info) {
    localStorage.setItem(key, JSON.stringify(info));
  }
};

const sendMessageToFlutter = async (key: string, data: object): Promise<object> => {
  return await window.flutter_inappwebview.callHandler(key, data);
};

const isFlutter = (): boolean => {
  return window.flutter_inappwebview ? true : false;
};

const callbackForFlutter = (callbackName: FlutterCallbackType, args: unknown) => {
  console.log('callbackForFlutter', callbackName, args);

  switch (callbackName) {
    case 'projectPurchase':
      saveInfoToLocalStorage<ProjectLite>('projectInfo', args as string);
      break;
    case 'gamePurchase':
      saveInfoToLocalStorage<Game>('gameInfo', args as string);
      break;
    case 'donationPurchase':
      saveInfoToLocalStorage<Creator>('creatorInfo', args as string);
      break;
  }
};

export const initializeFlutterInterface = () => {
  window.sendMessageToFlutter = sendMessageToFlutter;
  window.isFlutter = isFlutter;
  window.callbackForFlutter = callbackForFlutter;
};
