import { useMemo } from 'react';
import { PurchaseTexts } from '@src/constants/text';

export const useText = (type: PurchaseMessageDataType) => {
  const text = useMemo(() => {
    return PurchaseTexts[type];
  }, [type]);

  return text;
};
