import { useState, useEffect, useCallback, useRef } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { css } from '@emotion/css';

interface DonationText {
  profileImage: string;
  userName: string;
  churro: string;
}

interface RollingBannerProps {
  bannerItems: DonationText[];
  interval?: number;
  isRandom?: boolean;
}

export const RollingBanner = ({ bannerItems, interval = 3000, isRandom = false }: RollingBannerProps) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [nextIndex, setNextIndex] = useState<number>(1);
  const [isAnimating, setIsAnimating] = useState<boolean>(false);
  const timeoutRef = useRef<number | null>(null);

  const getNextIndex = useCallback(
    (currentIndex: number): number => {
      if (bannerItems.length <= 1) return currentIndex;
      if (isRandom) {
        let nextIndex;
        for (let i = 0; i < bannerItems.length; i++) {
          nextIndex = Math.floor(Math.random() * bannerItems.length);
          if (nextIndex !== currentIndex) {
            return nextIndex;
          }
        }
        // 만약 모든 시도에서 같은 인덱스가 나왔다면, 그냥 다음 인덱스를 반환
        return (currentIndex + 1) % bannerItems.length;
      } else {
        return (currentIndex + 1) % bannerItems.length;
      }
    },
    [bannerItems.length, isRandom],
  );

  const animateBanner = useCallback(() => {
    const next = getNextIndex(currentIndex);
    setNextIndex(next);
    setIsAnimating(true);

    if (timeoutRef.current !== null) clearTimeout(timeoutRef.current);

    timeoutRef.current = window.setTimeout(() => {
      setCurrentIndex(next);
      setIsAnimating(false);
    }, 490); // 애니메이션 시간보다 약간 짧게 설정
  }, [currentIndex, getNextIndex]);

  useEffect(() => {
    if (bannerItems.length <= 1) return;

    const timer = setInterval(animateBanner, interval);

    return () => {
      clearInterval(timer);
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [bannerItems.length, interval, animateBanner]);

  if (bannerItems.length === 0) {
    return null;
  }

  return (
    <BannerWrapper>
      <BannerContent key={`current-${currentIndex}`} isAnimating={isAnimating} position='current'>
        <img src={`${bannerItems[currentIndex].profileImage}`} alt='profile' />
        <p className={BannerNormalText}>
          <b>{bannerItems[currentIndex].userName}</b>님이&nbsp;
        </p>
        <p className={BannerChurroText}>{bannerItems[currentIndex].churro}츄로</p>
        <p className={BannerNormalText}>를 후원했어요!</p>
      </BannerContent>
      {bannerItems.length > 1 && (
        <BannerContent key={`next-${nextIndex}`} isAnimating={isAnimating} position='next'>
          <img src={`${bannerItems[nextIndex].profileImage}`} alt='profile' />
          <p className={BannerNormalText}>
            <b>{bannerItems[nextIndex].userName}</b>님이&nbsp;
          </p>
          <p className={BannerChurroText}>{bannerItems[nextIndex].churro}츄로</p>
          <p className={BannerNormalText}>를 후원했어요!</p>
        </BannerContent>
      )}
    </BannerWrapper>
  );
};

const slideDown = keyframes`
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
`;

const slideOut = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
`;

const BannerWrapper = styled.div`
  width: 100%;
  height: 40px; // 배너의 높이 설정
  overflow: hidden;
  position: relative;
  background-color: #f5f5f5;
`;

const BannerContent = styled.div<{ isAnimating: boolean; position: 'current' | 'next' }>`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${(props) => {
      if (props.isAnimating) {
        return props.position === 'current' ? slideOut : slideDown;
      }
      return 'none';
    }}
    0.5s ease-in-out;
  ${(props) => props.position === 'next' && !props.isAnimating && 'transform: translateY(-100%);'}

  > img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 8px;
  }
`;

const BannerNormalText = css`
  color: var(--black, #191919);

  /* Body2 */
  font-family: var(--font-family-Pretendard, Pretendard);
  font-size: var(--font-size-font-size-4, 14px);
  font-style: normal;
  font-weight: var(--font-weight-font-weight-regular, 500);
  line-height: var(--line-height-font-height-4, 20px); /* 142.857% */
  letter-spacing: -0.1px;
`;

const BannerChurroText = css`
  color: var(--text-text-primary, #7800ff);

  /* Subhead3 */
  font-family: var(--font-family-Pretendard, Pretendard);
  font-size: var(--font-size-font-size-4, 14px);
  font-style: normal;
  font-weight: var(--font-weight-font-weight-bold, 600);
  line-height: var(--line-height-font-height-4, 20px);
`;
