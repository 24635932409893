import { PurchaseContext } from '@src/providers/purchase';
import { useContext } from 'react';

export const usePurchase = () => {
  const context = useContext(PurchaseContext);

  if (!context) {
    throw new Error('usePurchase must be used within a PurchaseProvider');
  }

  return context;
};
