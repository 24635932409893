import { css, keyframes } from '@emotion/css';
import { Button } from '@src/components/common/Button';

interface TicketBuyButtonProps {
  isQueryButtonVisible?: boolean;
  onClickBuy?: () => void;
  onClickQuery?: () => void;
}

export const TicketBuyAndQueryButton = ({
  isQueryButtonVisible = false,
  onClickBuy,
  onClickQuery,
}: TicketBuyButtonProps) => {
  return (
    <div className={TicketBuyStyle}>
      {isQueryButtonVisible && <Button onClick={onClickQuery} label='구매내역 보기' isPrimary={false} />}
      <Button onClick={onClickBuy} label='티켓 구매하기' />
    </div>
  );
};

const slideUp = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

const TicketBuyStyle = css`
  position: fixed;
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
  max-width: 460px;
  padding: 8px 16px;
  align-items: flex-start;
  bottom: 0;
  animation: ${slideUp} 0.7s ease-in-out; // 애니메이션 추가
`;
