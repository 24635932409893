import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { App } from '@src/App';
import { initializeFlutterInterface } from '@src/utils/flutter-interface';
import { PurchaseProvider } from './providers/purchase';
import { ModalProvider } from './providers/modal';
import { LoadingProvider } from './providers/loading';
import { VerificationProvider } from './providers/verification';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import '@src/globals.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
    },
    mutations: {},
  },
});

initializeFlutterInterface();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <QueryClientProvider client={queryClient}>
    <ReactQueryDevtools initialIsOpen={false} />
    <LoadingProvider>
      <ModalProvider>
        <PurchaseProvider>
          <VerificationProvider>
            <App />
          </VerificationProvider>
        </PurchaseProvider>
      </ModalProvider>
    </LoadingProvider>
  </QueryClientProvider>,
);
