import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { TopNavigation } from '@src/components/TopNavigationBar';
import { VerifyCodeRequest } from '@src/components/features/Verify/VerifyCodeRequest';
import { VerifyCodeCheck } from '@src/components/features/Verify/VerifyCodeCheck';
import { useEffect, useState } from 'react';
import { css } from '@emotion/css';
import { OrdererInput } from '@src/components/features/Verify/OrdererInput';
import { useVerification } from '@src/hooks/useVerification';
import { VerifySuccess } from '@src/models/verify';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const VerifyPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const queryMode = searchParams.get('mode') === 'query';

  const components = queryMode
    ? [VerifyCodeRequest, VerifyCodeCheck]
    : [VerifyCodeRequest, VerifyCodeCheck, OrdererInput];

  const { phoneNumber, verifySuccess, setIsVerified, setPhoneNumber, setVerifier, setVerifySuccess } =
    useVerification();

  const [isActiveCompleteVerify, setIsActiveCompleteVerify] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState<'right' | 'left'>('right');

  const CurrentComponent = components[currentIndex];

  const goToNextComponent = () => {
    if (currentIndex === components.length - 1) return;
    setDirection('right');
    setCurrentIndex((prevIndex) => (prevIndex + 1) % components.length);
  };

  const goToPreviousComponent = () => {
    if (currentIndex === 0) {
      navigate(-1);
      return;
    }

    setDirection('left');
    setCurrentIndex((prevIndex) => (prevIndex - 1 + components.length) % components.length);
  };

  const updatePhoneNumber = (phoneNumber: string) => setPhoneNumber(phoneNumber);
  const updateIsVerified = (isVerified: boolean) => setIsVerified(isVerified);
  const updateVerifySuccess = (verifySuccess: VerifySuccess) => setVerifySuccess(verifySuccess);
  const updateVerifier = (verifier: string) => setVerifier(verifier);
  const updateIsActiveCompleteVerify = (isActive: boolean) => setIsActiveCompleteVerify(isActive);

  useEffect(() => {
    if (verifySuccess?.id && isActiveCompleteVerify) {
      completeVerify();
    }
  }, [verifySuccess, isActiveCompleteVerify]);

  const completeVerify = async () => {
    if (!verifySuccess) return;

    if (queryMode) navigate(`/orders?authId=${verifySuccess?.id}`, { replace: true });
    else navigate('/checkout', { replace: true });
  };

  return (
    <>
      <TopNavigation title='' onClickBack={goToPreviousComponent} />
      <TransitionGroup className='transitions-wrapper'>
        <CSSTransition key={currentIndex} timeout={300} classNames={direction}>
          <div className={slideTransitionStyles}>
            <CurrentComponent
              queryMode={queryMode}
              phoneNumber={phoneNumber}
              goToNextComponent={goToNextComponent}
              updatePhoneNumber={updatePhoneNumber}
              updateIsVerified={updateIsVerified}
              updateVerifySuccess={updateVerifySuccess}
              updateVerifier={updateVerifier}
              updateIsActiveCompleteVerify={updateIsActiveCompleteVerify}
              onClickCompleteVerify={completeVerify}
            />
          </div>
        </CSSTransition>
      </TransitionGroup>
    </>
  );
};

const slideTransitionStyles = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 460px;
  justify-content: flex-start;
  align-items: start;
`;

document.head.insertAdjacentHTML(
  'beforeend',
  `<style>
    .transitions-wrapper {
      position: relative;
      overflow: hidden;
      height: calc(var(--vh, 1vh) * 100);
      width: 100vw;
    }
    .right-enter {
      transform: translateX(100%);
    }
    .right-enter-active {
      transform: translateX(0);
      transition: transform 300ms ease-in-out;
    }
    .right-exit {
      transform: translateX(0);
    }
    .right-exit-active {
      transform: translateX(100%);
      transition: transform 300ms ease-in-out;
    }
    .left-enter {
      transform: translateX(-100%);
    }
    .left-enter-active {
      transform: translateX(0);
      transition: transform 300ms ease-in-out;
    }
    .left-exit {
      transform: translateX(0);
    }
    .left-exit-active {
      transform: translateX(100%);
      transition: transform 300ms ease-in-out;
    }
  </style>`,
);
