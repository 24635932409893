import { css } from '@emotion/css';
import { Accordion } from '@src/components/common/Accordion';
import { useState } from 'react';

export const PaymentOrdererInfo = () => {
  const [isExpanded, setIsExpanded] = useState(true);

  const handleToggle = () => setIsExpanded(!isExpanded);

  return (
    <Accordion title='구매자 정보' isExpanded={isExpanded} onToggle={handleToggle}>
      <div className={PaymentOrdererInfoContentStyle}>
        <div className={PaymentOrdererInfoContentContactStyle}>
          <span>구매자명</span>
          <p>홍길동</p>
        </div>
        <div className={PaymentOrdererInfoContentContactStyle}>
          <span>휴대폰 번호</span>
          <p>010-1234-1234</p>
        </div>
      </div>
    </Accordion>
  );
};

const PaymentOrdererInfoContentStyle = css`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  gap: 24px;
`;

const PaymentOrdererInfoContentContactStyle = css`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 8px;
  > span {
    color: var(--text-text-description, #555);

    /* Body2 */
    font-size: var(--font-size-font-size-5, 14px);
    font-style: normal;
    font-weight: var(--font-weight-font-weight-regular, 400);
    line-height: var(--line-height-font-height-5, 20px); /* 142.857% */
    letter-spacing: -0.1px;
  }

  > p {
    color: var(--text-text-default, #1a1a1a);

    /* Body1 */
    font-size: var(--font-size-font-size-4, 16px);
    font-style: normal;
    font-weight: var(--font-weight-font-weight-regular, 400);
    line-height: var(--line-height-font-height-4, 22px); /* 137.5% */
    letter-spacing: -0.1px;
  }
`;
