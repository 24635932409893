import { css } from '@emotion/css';
import { OrderItem } from './OrderItem';
import { OrderInfo } from '@src/models/order-info';
import { ProductMetaForTicket } from '@src/models/product';
import { useNavigate } from 'react-router-dom';

interface OrderGroupProps {
  orders: OrderInfo<'Ticket', ProductMetaForTicket>[];
}

const groupOrdersByDate = (orders: OrderInfo<'Ticket', ProductMetaForTicket>[]) => {
  // 날짜별로 그룹화한 결과를 저장할 객체 생성
  const groupedOrders = orders.reduce(
    (groups, order) => {
      // UTC 시간을 로컬 시간으로 변환하고 yyyy-mm-dd 형식으로 포맷팅
      const localDate = new Date(order.createdAt).toLocaleDateString('en-CA').split('T')[0]; // yyyy-mm-dd 형식으로 추출

      // 그룹화된 결과에 날짜가 없다면 빈 배열로 초기화
      if (!groups[localDate]) groups[localDate] = [];

      // 해당 날짜에 주문 추가
      groups[localDate].push(order);
      return groups;
    },
    {} as Record<string, OrderInfo<'Ticket', ProductMetaForTicket>[]>,
  );

  // 그룹화된 결과를 날짜 기준으로 정렬하여 가장 최근 날짜가 첫 번째로 오도록 정렬
  const sortedGroupedOrders = Object.entries(groupedOrders)
    .sort(([dateA], [dateB]) => new Date(dateB).getTime() - new Date(dateA).getTime())
    .reduce(
      (sortedGroups, [date, orders]) => {
        sortedGroups[date] = orders;
        return sortedGroups;
      },
      {} as Record<string, OrderInfo<'Ticket', ProductMetaForTicket>[]>,
    );

  return sortedGroupedOrders;
};

export const OrderGroup = ({ orders }: OrderGroupProps) => {
  const navigate = useNavigate();
  const groupedOrders = groupOrdersByDate(orders);

  const onClickOrder = (order: OrderInfo<'Ticket', ProductMetaForTicket>) => {
    navigate(`/orders/${order.orderId}`);
  };

  return (
    <div className={OrderGroupStyle}>
      {Object.entries(groupedOrders).map(([date, orders], index) => (
        <div className={OrderGroupContentStyle} key={index}>
          <p className={OrderGroupContentHeaderStyle}>{date}</p>
          {orders.map((order, idx) => (
            <OrderItem key={idx} order={order} padding='16px' onClick={() => onClickOrder(order)} />
          ))}
        </div>
      ))}
    </div>
  );
};

const OrderGroupStyle = css`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  gap: 40px;
`;

const OrderGroupContentStyle = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const OrderGroupContentHeaderStyle = css`
  color: var(--text-text-default, #1a1a1a);
  /* Subhead2 */
  font-family: var(--font-family-Pretendard, Pretendard);
  font-size: var(--font-size-font-size-4, 16px);
  font-style: normal;
  font-weight: var(--font-weight-font-weight-bold, 700);
  line-height: var(--line-height-font-height-4, 22px); /* 137.5% */
`;
