import React, { createContext, useState, ReactNode } from 'react';

interface LoadingState {
  isLoading: boolean;
  showLoading: () => void;
  hideLoading: () => void;
}

const initialLoadingState: LoadingState = {
  isLoading: false,
  showLoading: () => {},
  hideLoading: () => {},
};

const LoadingContext = createContext<LoadingState>(initialLoadingState);

const LoadingProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isLoading, setIsShown] = useState(false);

  const showLoading = () => setIsShown(true);
  const hideLoading = () => setIsShown(false);

  return <LoadingContext.Provider value={{ isLoading, showLoading, hideLoading }}>{children}</LoadingContext.Provider>;
};

export { LoadingContext, LoadingProvider };
