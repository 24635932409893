import { css } from '@emotion/css';
import ExclamationMarkIcon from '@src/assets/icon-exclamation-mark.svg';
import CheckboxCheckedIcon from '@src/assets/icon-checkbox-checked.svg';
import CheckboxUnCheckedIcon from '@src/assets/icon-checkbox-unchecked.svg';

interface PaymentNoticeProps {
  isChecked: boolean;
  updateIsChecked: () => void;
}

export const PaymentNotice = ({ isChecked = false, updateIsChecked }: PaymentNoticeProps) => {
  return (
    <div className={PaymentNoticeStyle}>
      <div className={PaymentNoticeContentStyle}>
        <div className={PaymentNoticeTitleStyle}>
          <img src={ExclamationMarkIcon} alt='알림' />
          <p>꼭 확인해주세요!</p>
        </div>
        <div className={PaymentNoticeDescriptionStyle}>
          <li>신용카드 결제는 일시불만 가능합니다.</li>
          <li>결제한 티켓 및 환불 기간이 지나면 환불이 불가합니다.</li>
          <li>티켓 코드가 등록된 경우 환불이 불가합니다.</li>
        </div>
      </div>
      <div className={PaymentNoticeCheckboxStyle}>
        <img src={isChecked ? CheckboxCheckedIcon : CheckboxUnCheckedIcon} alt='체크박스' onClick={updateIsChecked} />
        <div className={PaymentNoticeCheckboxTextStyle}>
          <span
            onClick={() => {
              window.open('https://uniquegoodcompany.notion.site/dd7da22c73854747ae333c5924d6e06a');
            }}
          >
            개인정보 수집 및 이용
          </span>
          <p>에 동의합니다</p>
        </div>
      </div>
    </div>
  );
};

const PaymentNoticeStyle = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const PaymentNoticeContentStyle = css`
  display: flex;
  padding: var(--padding-card, 16px);
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  border-radius: 16px;
  background: var(--surface-surface-transparent-primary, #f4f0ff);
`;

const PaymentNoticeTitleStyle = css`
  display: flex;
  align-items: center;
  gap: 4px;
  align-self: stretch;

  > p {
    color: var(--text-text-default, #1a1a1a);

    /* Subhead2 */
    font-size: var(--font-size-font-size-4, 16px);
    font-style: normal;
    font-weight: var(--font-weight-font-weight-bold, 700);
    line-height: var(--line-height-font-height-4, 22px); /* 137.5% */
  }
`;

const PaymentNoticeDescriptionStyle = css`
  padding-left: 8px;
  > li {
    color: var(--text-text-description, #555);

    /* Body2 */
    font-family: var(--font-family-Pretendard, Pretendard);
    font-size: var(--font-size-font-size-5, 14px);
    font-style: normal;
    font-weight: var(--font-weight-font-weight-regular, 400);
    line-height: var(--line-height-font-height-5, 20px); /* 142.857% */
    letter-spacing: -0.1px;
  }
`;

const PaymentNoticeCheckboxStyle = css`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const PaymentNoticeCheckboxTextStyle = css`
  display: flex;
  align-items: center;

  > span {
    color: var(--text-text-default, #1a1a1a);

    /* Body2 */
    font-size: var(--font-size-font-size-5, 14px);
    font-style: normal;
    font-weight: var(--font-weight-font-weight-regular, 400);
    line-height: var(--line-height-font-height-5, 20px); /* 142.857% */
    letter-spacing: -0.1px;
    text-decoration-line: underline;
  }

  > p {
    color: var(--text-text-default, #1a1a1a);

    /* Body2 */
    font-size: var(--font-size-font-size-5, 14px);
    font-style: normal;
    font-weight: var(--font-weight-font-weight-regular, 400);
    line-height: var(--line-height-font-height-5, 20px);
    letter-spacing: -0.1px;
  }
`;
