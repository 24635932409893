import axios from 'axios';
import { Response } from '@src/models/response';
import { QueryFunctionContext } from '@tanstack/react-query';

const baseURL = import.meta.env.VITE_REALWORLD_CORE_API_URL;
const authURL = import.meta.env.VITE_REALWORLD_AUTH_API_URL;

async function apiRequest<T extends object>(
  method: HttpMethodType,
  url: string,
  apiVersion: string,
  queryObject?: QueryFunctionContext,
  useAuthUrl?: boolean,
): Promise<Response<T> | Response<T[]>>;
async function apiRequest<T extends object, R extends object>(
  method: HttpMethodType,
  url: string,
  apiVersion: string,
  body?: T,
  useAuthUrl?: boolean,
): Promise<Response<R> | Response<R[]>>;

async function apiRequest<T extends object, R extends object>(
  method: HttpMethodType,
  url: string,
  apiVersion: string,
  queryObjectOrBody?: QueryFunctionContext | T,
  useAuthUrl?: boolean,
): Promise<Response<T> | Response<T[]> | Response<R> | Response<R[]>> {
  const apiUrl = useAuthUrl ? authURL : baseURL; // 동적으로 API URL 결정
  const api = axios.create({
    baseURL: apiUrl,
    headers: { 'Content-Type': 'application/json', 'x-rwd-api-version': apiVersion },
  });

  api.interceptors.request.use(
    async (config) => {
      const accessToken = localStorage.getItem('accessToken');
      if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;

      return config;
    },
    (error) => Promise.reject(error),
  );

  switch (method) {
    case 'get': {
      const params =
        queryObjectOrBody && 'queryKey' in queryObjectOrBody && queryObjectOrBody.queryKey.length > 1
          ? queryObjectOrBody.queryKey[1]
          : {};
      return api
        .get<Response<T> | Response<T[]>>(url, { params: params })
        .then((res) => res.data as Response<T> | Response<T[]>);
    }
    case 'post': {
      return api
        .post<Response<R> | Response<R[]>>(url, JSON.stringify(queryObjectOrBody!))
        .then((res) => res.data as Response<R> | Response<R[]>);
    }

    default:
      throw new Error(`Unsupported method: ${method}`);
  }
}

export { apiRequest };
