import { css } from '@emotion/css';
import { useState } from 'react';
import { Accordion } from '@src/components/common/Accordion';
import { PaymentOptionItem } from './PaymentOptionItem';

const paymentOptions = [
  { name: '옵션명', price: 18000, quantity: 1 },
  { name: '옵션명', price: 40000, quantity: 5 },
  { name: '옵션명', price: 40000, quantity: 10 },
  { name: '옵션명', price: 40000, quantity: 10 },
];

export const PaymentOption = () => {
  const [isExpanded, setIsExpanded] = useState(true);

  const handleToggle = () => setIsExpanded(!isExpanded);

  return (
    <Accordion title='구매 옵션' isExpanded={isExpanded} onToggle={handleToggle}>
      <div className={PaymentOptionContentTitleStyle}>
        <img src='https://cdn.realworld.to/adventure/240530-092650_c3kGK9nJPFM.jpg' alt='구매 상품 제목' />
        <div className={PaymentOptionContentTextStyle}>
          <p>구매 상품 제목adowhajdohwaodhoashdohwaodhsaohdowahdoh</p>
          <p>제목이 길어지면 이렇게 노출됩니다..awdwadhakwhdakjhd..</p>
        </div>
      </div>
      <div className={PaymentOptionItemGroupStyle}>
        {paymentOptions.map((option, index) => (
          <PaymentOptionItem key={index} name={option.name} price={option.price} quantity={option.quantity} />
        ))}
      </div>
    </Accordion>
  );
};

const PaymentOptionContentTitleStyle = css`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  gap: 16px;

  > img {
    object-fit: cover;
    border-radius: 11.429px;
    background: lightgray 50% / cover no-repeat;
    width: 80px;
    height: 80px;
  }
`;

const PaymentOptionContentTextStyle = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  > p {
    overflow: hidden;
    color: var(--text-text-default, #1a1a1a);
    text-overflow: ellipsis;
    white-space: nowrap;

    /* Subhead2 */
    font-size: var(--font-size-font-size-4, 16px);
    font-style: normal;
    font-weight: var(--font-weight-font-weight-bold, 700);
    line-height: var(--line-height-font-height-4, 22px); /* 137.5% */
  }
`;

const PaymentOptionItemGroupStyle = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
`;
