import { css } from '@emotion/css';

interface PaymentOptionItemProps {
  name: string;
  price: number;
  quantity: number;
}

export const PaymentOptionItem = ({ name, price, quantity }: PaymentOptionItemProps) => {
  return (
    <div className={PaymentOptionItemStyle}>
      <div className={PaymentOptionItemTextStyle}>
        <p>{name}</p>
        <span>수량 {quantity}개</span>
      </div>
      <span>{price.toLocaleString()}원</span>
    </div>
  );
};

const PaymentOptionItemStyle = css`
  display: flex;
  padding: 12px var(--padding-card, 16px);
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  align-self: stretch;

  background: var(--background-section-on-card, #f5f5f5);
  border-radius: 16px;

  > span {
    color: var(--text-text-default, #1a1a1a);

    /* Subhead2 */
    font-family: var(--font-family-Pretendard, Pretendard);
    font-size: var(--font-size-font-size-4, 16px);
    font-style: normal;
    font-weight: var(--font-weight-font-weight-bold, 700);
    line-height: var(--line-height-font-height-4, 22px); /* 137.5% */
  }
`;

const PaymentOptionItemTextStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;

  > p {
    overflow: hidden;
    color: var(--text-text-default, #1a1a1a);
    text-overflow: ellipsis;

    /* Subhead3 */
    font-size: var(--font-size-font-size-5, 14px);
    font-style: normal;
    font-weight: var(--font-weight-font-weight-bold, 700);
    line-height: var(--line-height-font-height-5, 20px); /* 142.857% */
  }

  > span {
    overflow: hidden;
    color: var(--text-text-description, #555);
    text-overflow: ellipsis;

    /* Body2 */
    font-size: var(--font-size-font-size-5, 14px);
    font-style: normal;
    font-weight: var(--font-weight-font-weight-regular, 400);
    line-height: var(--line-height-font-height-5, 20px); /* 142.857% */
    letter-spacing: -0.1px;
  }
`;
