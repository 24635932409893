import { useEffect } from 'react';

export const usePostMessage = (
  targetOrigin?: string,
  onMessage?: (message: PostMessageType<MessageDataType>) => void,
) => {
  useEffect(() => {
    const messageHandler = (event: MessageEvent) => {
      if (event.origin !== targetOrigin) {
        console.warn('Received message from unknown origin:', event.origin);
        return;
      }
      if (onMessage) onMessage(event.data);
    };

    window.addEventListener('message', messageHandler);

    return () => {
      window.removeEventListener('message', messageHandler);
    };
  }, [onMessage, targetOrigin]);

  const postMessageToParent = <T>(message: PostMessageType<T>) => {
    if (!window.isFlutter() && targetOrigin === import.meta.env.VITE_POST_MESSAGE_TARGET_URL) {
      window.opener && window.opener.postMessage(message, targetOrigin);
    }

    if (window.isFlutter()) {
      window.sendMessageToFlutter('postMessage', message);
    }
  };

  return postMessageToParent;
};
