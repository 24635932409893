import { css } from '@emotion/css';
import { useGetProducts } from '@src/apis/queries';
import { TicketBottomDrawer } from '@src/components/features/Ticket/TicketBottomDrawer';
import { TicketBuyAndQueryButton } from '@src/components/features/Ticket/TicketBuyAndQueryButton';
import { Product, ProductMetaForTicket } from '@src/models/product';
import { useEffect, useState } from 'react';
import LandingImageOne from '@src/assets/landing/landing-1.png';
import LandingImageTwo from '@src/assets/landing/landing-2.png';
import LandingImageThree from '@src/assets/landing/landing-3.png';
import LandingImageFour from '@src/assets/landing/landing-4.png';
import LandingImageFive from '@src/assets/landing/landing-5.png';
import LandingImageSix from '@src/assets/landing/landing-6.png';
import { Img } from 'react-image';
import { useNavigate } from 'react-router-dom';

export const TicketPage = () => {
  const purchasedTicket = localStorage.getItem('isPurchasedTicket');
  const navigate = useNavigate();
  const { data: products } = useGetProducts({ paymentMethod: 'TossPaymentPg', productType: 'Ticket' });

  const [isBottomDrawerOpen, setIsBottomDrawerOpen] = useState(false);
  const [ticketProducts, setTicketProducts] = useState<Product<'Ticket', ProductMetaForTicket>[]>([]);

  const updateIsBottomDrawerOpen = (isOpen: boolean) => setIsBottomDrawerOpen(isOpen);

  useEffect(() => {
    if (products?.success) {
      const ticketProducts = products.data as Product<'Ticket', ProductMetaForTicket>[];
      setTicketProducts(ticketProducts);
    }
  }, [products]);

  return (
    <>
      <div className={TicketPageStyle}>
        <div className={TicketInfoStyle}>
          <Img src={LandingImageOne} />
          <Img src={LandingImageTwo} />
          <Img src={LandingImageThree} />
          <Img src={LandingImageFour} />
          <Img src={LandingImageFive} />
          <Img src={LandingImageSix} />
        </div>
        <TicketBuyAndQueryButton
          isQueryButtonVisible={purchasedTicket === 'true'}
          onClickQuery={() => navigate('/verify?mode=query')}
          onClickBuy={() => updateIsBottomDrawerOpen(true)}
        />
      </div>
      <TicketBottomDrawer
        isOpen={isBottomDrawerOpen}
        updateIsBottomDrawerOpen={updateIsBottomDrawerOpen}
        ticketProducts={ticketProducts}
      />
    </>
  );
};

const TicketPageStyle = css`
  width: 100%;
  align-items: center;
`;

const TicketInfoStyle = css`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  padding-bottom: 32px;
`;
