import { css } from '@emotion/css';
import styled from '@emotion/styled';
import IconChurros from '@src/assets/icon-churros.svg';
import { Product, ProductMetaForDonation } from '@src/models/product';
import { Img } from 'react-image';
import { LoadingSpinner } from '@src/components/common/LoadingSpinner';

interface DonationItemProps {
  index: number;
  selectedIndex: number;
  data: Product<'Donation', ProductMetaForDonation>;
  isRecentlyDonated?: boolean;
  onClick?: () => void;
}

export const DonationItem = (props: DonationItemProps) => {
  const isSelected = props.index === props.selectedIndex;
  const productMeta: ProductMetaForDonation = props.data.meta as ProductMetaForDonation;

  return (
    <>
      <DonationItemStyle isSelected={isSelected} onClick={props.onClick}>
        {props.isRecentlyDonated && <div className={RecentlyDonatedLabel}>최근에 후원됐어요!</div>}
        <div className={DonationImageBoxStyle}>
          <Img
            style={{ width: '48px', height: '48px' }}
            src={props.data.productImageUrl ? props.data.productImageUrl : IconChurros}
            alt='product_image'
            loader={<LoadingSpinner />}
          />
        </div>
        <div className={TextGroupStyle}>
          <div className={DonationNameStyle}>{props.data.name}</div>
        </div>
        <div className={LabelGroupStyle}>
          <div className={ChurroLabelStyle}>
            {productMeta.pointPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 츄로
          </div>
          <div className={PriceLabelStyle}>{props.data.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 원</div>
        </div>
      </DonationItemStyle>
    </>
  );
};

const DonationItemStyle = styled.div<{ isSelected: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  gap: 8px;
  border-radius: 8px;
  flex: 1;
  position: relative;
  ${(props) =>
    props.isSelected
      ? 'border: 1px solid var(--light-primary-500, #7800FF);'
      : 'border: 1px solid var(--light-gray-200, #E8E8E8);'};
  ${(props) => (props.isSelected ? 'background: var(--light-primary-100, #F4F0FF);' : 'background: white;')};
`;
const RecentlyDonatedLabel = css`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  height: 22px;
  padding: 0px 8px;
  border-radius: 999px;
  background: var(--surface-surface-inverse-default, #1a1a1a);

  /* Label2 */
  color: var(--text-text-on-color, #fff);
  font-family: var(--font-family-Pretendard, Pretendard);
  font-size: var(--font-size-font-size-6, 10px);
  font-style: normal;
  font-weight: var(--font-weight-font-weight-bold, 600);
  line-height: var(--line-height-font-height-6, 14px); /* 140% */
  white-space: nowrap;
`;

const DonationImageBoxStyle = css`
  width: 48px;
  height: 48px;
`;

const TextGroupStyle = css`
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  word-break: keep-all;
  color: var(--black, #191919);
`;

const DonationNameStyle = css`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  white-space: nowrap;
`;

const LabelGroupStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
`;

const ChurroLabelStyle = css`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  line-height: 20px;
  letter-spacing: -0.1px;

  text-align: center;
  white-space: nowrap;
`;

const PriceLabelStyle = css`
  color: var(--light-gray-600, #8f9092);

  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 140% */
`;
