import { css, keyframes } from '@emotion/css';
import { Img } from 'react-image';
import { Button } from '@src/components/common/Button';
import { useSearchParams } from 'react-router-dom';
import RegisterImageOne from '@src/assets/register/register-1.png';
import RegisterImageTwo from '@src/assets/register/register-2.png';
import RegisterImageThree from '@src/assets/register/register-3.png';
import RegisterImageFour from '@src/assets/register/register-4.png';

export const TicketRegister = () => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');

  const onClickRegister = () => {
    alert('티켓 등록은 9월 25일 부터 가능합니다!');
    // window.location.href = `realworld://realtreasure?ticketCode=${code}`;
  };
  return (
    <>
      <div className={TicketRegisterPageStyle}>
        <Img src={RegisterImageOne} />
        <Img src={RegisterImageTwo} />
        <Img src={RegisterImageThree} />
        <Img src={RegisterImageFour} />
      </div>
      <div className={TicketRegisterStyle}>{code && <Button onClick={onClickRegister} label='티켓 등록하기' />}</div>
    </>
  );
};

const TicketRegisterPageStyle = css`
  width: 100%;
  align-items: center;
`;

const slideUp = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

const TicketRegisterStyle = css`
  position: fixed;
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
  max-width: 460px;
  padding: 8px 16px;
  align-items: flex-start;
  bottom: 0;
  animation: ${slideUp} 0.7s ease-in-out; // 애니메이션 추가
`;
