import { css } from '@emotion/css';
import { BoxInput } from '@src/components/common/BoxInput';
import { Button } from '@src/components/common/Button';
import { useEffect, useRef, useState } from 'react';

interface OrdererInputProps {
  updateVerifier: (verifier: string) => void;
  onClickCompleteVerify: () => void;
}

export const OrdererInput = ({ updateVerifier, onClickCompleteVerify }: OrdererInputProps) => {
  const [orderer, setOrderer] = useState('');
  const [keyboardHeight, setKeyboardHeight] = useState(0);
  const [isEnableButton, setIsEnableButton] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const handleViewportResize = () => {
      if (window.visualViewport) {
        const viewportHeight = window.visualViewport.height;
        const windowHeight = window.innerHeight;
        const calculatedKeyboardHeight = windowHeight - viewportHeight;

        // 키보드가 열렸다고 간주될 때만 키보드 높이를 설정
        if (calculatedKeyboardHeight > 0) {
          setKeyboardHeight(calculatedKeyboardHeight);
        } else {
          setKeyboardHeight(0);
        }
      }
    };

    window.visualViewport?.addEventListener('resize', handleViewportResize);

    return () => {
      window.visualViewport?.removeEventListener('resize', handleViewportResize);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus();
    }, 300);
  }, []);

  const handleValuesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // 한글 완전한 음절(가-힣)과 영문 문자만 필터링하여 배열 생성
    const validChars = value.match(/[\uAC00-\uD7A3A-Za-z]/g) || [];

    if (validChars.length >= 2) {
      updateVerifier(value);
      setIsEnableButton(true);
    } else {
      updateVerifier('');
      setIsEnableButton(false);
    }

    setOrderer(value);
  };

  return (
    <div className={OrdererPageStyle}>
      <div className={OrdererContentStyle}>
        <div className={OrdererTitleStyle}>
          <p>구매자명을</p>
          <p>입력해주세요</p>
        </div>
        <BoxInput
          maxLength={17}
          width='216px'
          placeholder='홍길동'
          value={orderer}
          onChange={handleValuesChange}
          ref={inputRef}
        />
      </div>
      <div className={OrdererButtonStyle(keyboardHeight)}>
        <Button label='인증 완료하기' isEnable={isEnableButton} onClick={onClickCompleteVerify} />
      </div>
    </div>
  );
};

const OrdererPageStyle = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 48px;
  padding-bottom: 65px;
`;

const OrdererContentStyle = css`
  display: flex;
  flex-direction: column;
  gap: 48px;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
`;

const OrdererTitleStyle = css`
  > p {
    color: var(--text-text-default, #1a1a1a);
    text-align: center;

    /* Hero02 */
    font-size: var(--font-size-font-size-1, 24px);
    font-style: normal;
    font-weight: var(--font-weight-font-weight-extrabold, 800);
    line-height: var(--line-height-font-height-1, 36px); /* 150% */
  }
`;

const OrdererButtonStyle = (keyboardHeight: number) => css`
  position: fixed;
  bottom: ${keyboardHeight > 0 ? `${keyboardHeight}px` : '0'}; /* 키보드가 열렸을 때 버튼을 올리는 높이 */
  width: 100%;
  max-width: 460px;
  display: flex;
  padding: 8px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  transition: 0.25s ease-out;
`;
