import { css } from '@emotion/css';

interface ButtonProps {
  onClick?: () => void;
  label?: string;
  isVisible?: boolean;
  isEnable?: boolean;
  isPrimary?: boolean;
}

export const Button = ({ onClick, label = '', isVisible = true, isEnable = true, isPrimary = true }: ButtonProps) => {
  if (!isVisible) return null;

  return (
    <div className={isPrimary ? PrimaryButtonStyle(isEnable) : SecondaryButtonStyle} onClick={onClick}>
      {label}
    </div>
  );
};

const PrimaryButtonStyle = (isEnable: boolean) => css`
  display: flex;
  width: 100%;
  height: 48px;
  padding: 0px 24px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: var(--button-M-48, 16px);
  background: ${isEnable ? '#7800ff' : 'grey'};

  color: var(--text-text-on-color, #fff);
  text-align: center;
  font-size: var(--font-size-font-size-4, 16px);
  font-style: normal;
  font-weight: var(--font-weight-font-weight-bold, 700);
  line-height: var(--line-height-font-height-4, 22px); /* 137.5% */
`;

const SecondaryButtonStyle = css`
  display: flex;
  width: 100%;
  height: 48px;
  padding: 0px 24px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: var(--button-M-48, 16px);
  border: 1px solid var(--border-border-default, #e8e8e8);
  background: white;

  color: var(--text-text-description, #555);
  text-align: center;
  font-size: var(--font-size-font-size-4, 16px);
  font-style: normal;
  font-weight: var(--font-weight-font-weight-bold, 700);
  line-height: var(--line-height-font-height-4, 22px); /* 137.5% */
`;
