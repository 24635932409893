import React, { forwardRef, ForwardedRef } from 'react';
import styled from '@emotion/styled';

interface BoxInputProps {
  width?: string;
  height?: string;
  maxLength: number;
  inputType?: 'text' | 'number' | 'password';
  className?: string;
  placeholder?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isFocusAnimation?: boolean;
  autoFocus?: boolean;
  readonly?: boolean;
}

export const BoxInput = forwardRef<HTMLInputElement, BoxInputProps>(
  (
    {
      width = '80px',
      height = '60px',
      maxLength = 4,
      inputType = 'text',
      placeholder = '',
      value,
      onChange,
      autoFocus = false,
      readonly = false,
    },
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    return (
      <div>
        <InputStyle
          width={width}
          height={height}
          ref={ref}
          type={inputType}
          inputMode={inputType === 'number' ? 'numeric' : 'text'}
          pattern={inputType === 'number' ? '[0-9]*' : undefined}
          maxLength={maxLength}
          value={value} // 부모로부터 전달된 value 사용
          onChange={onChange}
          placeholder={placeholder}
          aria-label='Input field'
          autoFocus={autoFocus}
          readOnly={readonly}
        />
      </div>
    );
  },
);

const InputStyle = styled.input<{ width?: string; height?: string }>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border-radius: 6px;
  outline: none;
  transition: all 0.2s;
  background: var(--surface-surface-card, #f5f5f5);
  caret-color: #7800ff;
  color: var(--text-text-default, #1a1a1a);
  text-align: center;

  /* Headline */
  font-size: var(--font-size-font-size-2, 20px);
  font-style: normal;
  font-weight: var(--font-weight-font-weight-extrabold, 400);
  line-height: var(--line-height-font-height-2, 28px); /* 140% */

  &:focus {
    border-color: #3b82f6;
    border: 1px solid var(--border-border-focus, #555);
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.12);
  }

  &::placeholder {
    color: var(--text-text-disabled, #bdbdbd);
    text-align: center;

    /* Headline */
    font-size: var(--font-size-font-size-2, 20px);
    font-style: normal;
    font-weight: var(--font-weight-font-weight-extrabold, 400);
    line-height: var(--line-height-font-height-2, 28px); /* 140% */
  }
`;
