import { css, keyframes } from '@emotion/css';
import { useModal } from '@src/hooks/useModal';
import AvatarIcon from '@src/assets/avatar.svg';
import RibbonHeartIcon from '@src/assets/ribbon-heart.png';
import { usePostMessage } from '@src/hooks/usePostMessage';
import { getCreatorInfoFromLocalStorage, getUserInfoFromLocalStorage } from '@src/utils/util';

export const Modal = () => {
  const { isOpen, closeModal } = useModal();
  const userInfo = getUserInfoFromLocalStorage();
  const creatorInfo = getCreatorInfoFromLocalStorage();
  const userName = userInfo?.name ?? '플레이어';
  const userProfile = userInfo?.profileImage ?? AvatarIcon;
  const creatorProfile = creatorInfo?.channelProfileImageUrl ?? AvatarIcon;

  const postMessageToParent = usePostMessage();
  const sendMessageToParent = () => {
    const closeMessage: PostMessageType<ActionMessage> = {
      type: 'action',
      data: {
        messageDataType: 'close',
        payload: { status: true },
      },
    };

    if (window.isFlutter()) postMessageToParent<ActionMessage>(closeMessage);
    else history.back();

    closeModal();
  };

  if (!isOpen) return null;

  return (
    <div className={ModalStyle}>
      <div className={ModalPopupStyle}>
        <div className={ModalPopupContentStyle}>
          <div className={ModalPopupContentImageStyle}>
            <img className={ModalPopupProfileImageStyle} src={userProfile} alt='avatar' />
            <img src={RibbonHeartIcon} alt='ribbon-heart' />
            <img className={ModalPopupProfileImageStyle} src={creatorProfile} alt='avatar' />
          </div>
          <p className={ModalPopupContentTitleStyle}>
            크리에이터에게<br></br>따뜻한 마음을 전해주세요
          </p>
          <p className={ModalPopupContentDescriptionStyle}>
            {userName}님의 소중한 후원은<br></br>크리에이터님의 작품 활동에 큰 도움이 될 거예요!
          </p>
          <div className={ModalPopupContentLetterSection}>
            <p className={ModalPopupContentLetterText}>
              후원하신 츄로는 내 닉네임이 적힌 <b>편지</b>와<br></br>함께 크리에이터님에게 <b>전달</b>될 예정이예요.
            </p>
          </div>
          <div className={ModalPopupButtonSectionStyle}>
            <div className={ModalPopupBackButtonStyle} onClick={sendMessageToParent}>
              돌아가기
            </div>
            <div className={ModalPopupBackConfirmStyle} onClick={closeModal}>
              확인
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const openAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const ModalStyle = css`
  z-index: 9999;
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 330px;
  height: 210px;
  justify-content: center;
  align-items: center;
  gap: 32px;
  background: var(--light-transparent-black-40, rgba(0, 0, 0, 0.4));
  width: 100%;
  height: 100%;
`;

const ModalPopupStyle = css`
  display: flex;
  width: 327px;
  max-width: 327px;
  padding: 32px 24px 24px 24px;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;

  border-radius: 24px;
  background: var(--surface-surface-on-color, #fff);

  /* portrait/24 */
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.12);
  animation: ${openAnimation} 0.5s ease-in-out;
`;

const ModalPopupContentStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  align-self: stretch;
`;

const ModalPopupContentImageStyle = css`
  display: flex;
  width: 160px;
  height: 80px;
  padding: 16px 0px;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  img {
    width: 48px;
    height: 48px;
  }
`;

const ModalPopupProfileImageStyle = css`
  display: flex;
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 100%;
  border: 1px solid var(--border-border-default, #e8e8e8);
`;

const ModalPopupContentTitleStyle = css`
  align-self: stretch;

  color: var(--text-text-default, #1a1a1a);
  text-align: center;

  /* Headline */
  font-size: var(--font-size-font-size-1, 20px);
  font-style: normal;
  font-weight: 650;
  line-height: var(--line-height-font-height-1, 28px); /* 140% */
`;

const ModalPopupContentDescriptionStyle = css`
  align-self: stretch;

  color: var(--text-text-description, #555);
  text-align: center;

  /* Body2 */
  font-family: var(--font-family-Pretendard, Pretendard);
  font-size: var(--font-size-font-size-4, 14px);
  font-style: normal;
  font-weight: var(--font-weight-font-weight-regular, 600);
  line-height: var(--line-height-font-height-4, 20px); /* 142.857% */
  letter-spacing: -0.1px;
`;

const ModalPopupContentLetterSection = css`
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;

  border-radius: 24px;
  background: var(--background-section-on-card, #f5f5f5);
`;

const ModalPopupContentLetterText = css`
  color: var(--text-text-default, #1a1a1a);
  text-align: center;

  /* Body2 */
  font-family: var(--font-family-Pretendard, Pretendard);
  font-size: var(--font-size-font-size-4, 14px);
  font-style: normal;
  font-weight: var(--font-weight-font-weight-regular, 400);
  line-height: var(--line-height-font-height-4, 20px); /* 142.857% */
  letter-spacing: -0.1px;
`;

const ModalPopupButtonSectionStyle = css`
  display: flex;
  width: 279px;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const ModalPopupBackButtonStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;

  border-radius: var(--button-M-48, 16px);
  background: var(--surface-surface-disabled, #e8e8e8);

  color: var(--text-text-description, #555);
  text-align: center;

  /* Subhead2 */
  font-family: var(--font-family-Pretendard, Pretendard);
  font-size: var(--font-size-font-size-3, 16px);
  font-style: normal;
  font-weight: var(--font-weight-font-weight-bold, 600);
  line-height: var(--line-height-font-height-3, 22px); /* 137.5% */
`;
const ModalPopupBackConfirmStyle = css`
  display: flex;
  height: 48px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;

  border-radius: var(--button-M-48, 16px);
  background: var(--surface-surface-primary, #7800ff);

  color: var(--text-text-on-color, #fff);
  text-align: center;

  /* Subhead2 */
  font-family: var(--font-family-Pretendard, Pretendard);
  font-size: var(--font-size-font-size-3, 16px);
  font-style: normal;
  font-weight: var(--font-weight-font-weight-bold, 600);
  line-height: var(--line-height-font-height-3, 22px); /* 137.5% */
`;
