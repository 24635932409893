import { css } from '@emotion/css';
import { VerifyCodeInput } from './VerifyCodeInput';
import { usePostVerifyCodeCheck, usePostVerifyCodeRequest } from '@src/apis/mutations';
import { useEffect, useState } from 'react';
import { useLoading } from '@src/hooks/useLoading';
import { VerifySuccess } from '@src/models/verify';
import { saveToSessionStorage } from '@src/utils/util';

interface VerifyCodeProps {
  queryMode: boolean;
  phoneNumber: string;
  goToNextComponent: () => void;
  updateIsVerified: (isVerified: boolean) => void;
  updateVerifySuccess: (verifySuccess: VerifySuccess) => void;
  updateIsActiveCompleteVerify: (isActive: boolean) => void;
}

export const VerifyCodeCheck = ({
  queryMode,
  phoneNumber,
  goToNextComponent,
  updateIsVerified,
  updateVerifySuccess,
  updateIsActiveCompleteVerify,
}: VerifyCodeProps) => {
  const { showLoading, hideLoading } = useLoading();

  const { mutateAsync: postVerifyCodeCheck } = usePostVerifyCodeCheck();
  const { mutateAsync: postVerifyCodeRequest } = usePostVerifyCodeRequest();

  const [verifyCode, setVerifyCode] = useState('');
  const [isNotValid, setIsNotValid] = useState(false);

  const updateIsNotValid = (isNotValid: boolean) => setIsNotValid(isNotValid);
  const handleResendCode = async () => await postVerifyCodeRequest({ target: phoneNumber });

  const handleValuesChange = async (combinedValue: string, isEdit: boolean) => {
    if (isEdit || !combinedValue) return;

    setVerifyCode(combinedValue);
  };

  const handleVerifyCode = async () => {
    showLoading();

    try {
      const result = await postVerifyCodeCheck({ verificationTarget: phoneNumber, code: verifyCode });

      if (result.success) {
        const verifySuccessData = result.data as VerifySuccess;
        setIsNotValid(false);
        updateIsVerified(true);
        updateVerifySuccess(verifySuccessData);
        saveToSessionStorage<string>('authId', verifySuccessData.id);

        setTimeout(() => {
          hideLoading();
          if (!queryMode) goToNextComponent();
          else updateIsActiveCompleteVerify(true);
        }, 800);
      }
    } catch (e) {
      hideLoading();
      setIsNotValid(true);
    }
  };

  useEffect(() => {
    if (verifyCode.length === 4) {
      handleVerifyCode();
    }
  }, [verifyCode]);

  return (
    <div className={VerifyPageStyle}>
      <div className={VerifyContentStyle}>
        <div className={VerifyTitleStyle}>
          <p>문자로 전송된</p>
          <p>인증번호를 입력해주세요</p>
        </div>
        <VerifyCodeInput
          isNotValid={isNotValid}
          onUpdateIsNotValid={updateIsNotValid}
          onValuesChange={handleValuesChange}
          onResendCode={handleResendCode}
        />
      </div>
    </div>
  );
};

const VerifyPageStyle = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 48px;
  padding-bottom: 65px;
`;

const VerifyContentStyle = css`
  display: flex;
  flex-direction: column;
  gap: 48px;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
`;

const VerifyTitleStyle = css`
  > p {
    color: var(--text-text-default, #1a1a1a);
    text-align: center;

    /* Hero02 */
    font-size: var(--font-size-font-size-1, 24px);
    font-style: normal;
    font-weight: var(--font-weight-font-weight-extrabold, 800);
    line-height: var(--line-height-font-height-1, 36px); /* 150% */
  }
`;
