import { css } from '@emotion/css';
import { Ticket, TicketCode } from '@src/models/ticket';
import { TicketStatusLabel } from '../Ticket/TicketStatusLabel';
import CopyIcon from '@src/assets/icon-copy.svg';
import { Product, ProductMetaForTicket } from '@src/models/product';
import { OrderInfo, OrdererInfo } from '@src/models/order-info';
import { kakaoShare } from '@src/utils/kakao';
import KakaoIcon from '@src/assets/icon-kakao.svg';
import TicketIcon from '@src/assets/icon-ticket.svg';

interface OrderTicketItemProps {
  orderer?: OrdererInfo;
  order: OrderInfo<'Ticket', ProductMetaForTicket>;
  products: Product<'Ticket', ProductMetaForTicket>[];
  tickets: Ticket[];
}

interface OrderTicketItemTicketCodeProduct {
  product: Product<'Ticket', ProductMetaForTicket>;
  ticket: Ticket;
}
// 두 배열을 참조 ID 기준으로 매핑하여 병합하는 함수
const mergeProductsAndTickets = (
  products: Product<'Ticket', ProductMetaForTicket>[],
  tickets: Ticket[],
): OrderTicketItemTicketCodeProduct[] => {
  const ticketMap = new Map(tickets.map((ticket) => [ticket.ticketId, ticket]));

  return products
    .map((product) => {
      const matchingTicket = ticketMap.get(product.meta!.ticketProductId);
      return matchingTicket ? { product, ticket: matchingTicket } : null;
    })
    .filter((item): item is OrderTicketItemTicketCodeProduct => item !== null);
};

export const OrderTicketItem = ({ orderer, products, tickets }: OrderTicketItemProps) => {
  if (!products || !tickets) return null;

  const mergedItems = mergeProductsAndTickets(products, tickets);

  const copyTicketCode = (ticketCode: string) => {
    navigator.clipboard.writeText(ticketCode);
    alert('티켓 코드가 복사되었습니다.');
  };

  const ticketActive = () => {
    alert('티켓 사용은 9월 25일부터 가능합니다.');
  };

  const ticketShare = (ticket: Ticket, ticketCode: TicketCode) => {
    const title = `${orderer?.ordererName}님이 티켓을 선물했어요!`;

    const code = ticketCode.code;
    const codeTitle = ticket.title;
    const codeDesc = ticket.description;
    const url = `https://purchase.realworld.to/ticket/register?code=${ticketCode.code}`;

    kakaoShare(title, code, codeTitle, codeDesc, url);
  };

  return (
    <>
      {mergedItems.map(({ product, ticket }) =>
        ticket.ticketCodeList.map((ticketCode, index) => (
          <div className={OrderTicketItemStyle} key={index}>
            <div className={OrderTicketItemContentStyle}>
              <TicketStatusLabel ticketStatus={ticketCode.status} />
              <div className={OrderTicketItemTextStyle}>
                <div className={OrderTicketItemCodeTitleStyle}>
                  <span>{ticket.title}</span>
                  <div className={OrderTicketItemCodeStyle} onClick={() => copyTicketCode(ticketCode.code)}>
                    <p>티켓코드 : {ticketCode.code}</p>
                    {ticketCode.status === 'Created' && <img src={CopyIcon} alt='copy' />}
                  </div>
                </div>
                <span>{product.price.toLocaleString()}원</span>
              </div>
            </div>
            {ticketCode.status === 'Created' && (
              <div className={OrderTicketItemButtonGroupStyle}>
                <div className={OrderTicketItemCopyButtonStyle} onClick={() => ticketShare(ticket, ticketCode)}>
                  <img src={KakaoIcon} alt='kakao' />
                  <span>공유하기</span>
                </div>
                <div className={OrderTicketItemShareButtonStyle} onClick={ticketActive}>
                  <img src={TicketIcon} alt='ticket' />
                  <span>코드 사용하기</span>
                </div>
              </div>
            )}
          </div>
        )),
      )}
    </>
  );
};

const OrderTicketItemStyle = css`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background: #f5f5f5;
  overflow: hidden;
`;

const OrderTicketItemContentStyle = css`
  width: 100%;
  gap: 4px;
  padding: 16px;
`;

const OrderTicketItemTextStyle = css`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  gap: 4px;

  > span {
    color: var(--text-text-default, #1a1a1a);

    /* Subhead2 */
    font-family: var(--font-family-Pretendard, Pretendard);
    font-size: var(--font-size-font-size-4, 16px);
    font-style: normal;
    font-weight: var(--font-weight-font-weight-bold, 700);
    line-height: var(--line-height-font-height-4, 22px); /* 137.5% */
  }
`;

const OrderTicketItemCodeTitleStyle = css`
  display: flex;
  flex-direction: column;
  max-width: 200px;
  gap: 4px;

  > span {
    color: var(--text-text-default, #1a1a1a);

    /* Subhead3 */
    font-family: var(--font-family-Pretendard, Pretendard);
    font-size: var(--font-size-font-size-5, 14px);
    font-style: normal;
    font-weight: var(--font-weight-font-weight-bold, 700);
    line-height: var(--line-height-font-height-5, 20px); /* 142.857% */
  }
`;

const OrderTicketItemCodeStyle = css`
  display: flex;
  align-items: center;
  gap: 4px;
  > p {
    overflow: hidden;
    color: var(--text-text-description, #555);
    text-overflow: ellipsis;

    /* Body2 */
    font-size: var(--font-size-font-size-5, 14px);
    font-style: normal;
    font-weight: var(--font-weight-font-weight-regular, 400);
    line-height: var(--line-height-font-height-5, 20px); /* 142.857% */
    letter-spacing: -0.1px;
  }

  > img {
    width: 14px;
    height: 14px;
  }
`;

const OrderTicketItemButtonGroupStyle = css`
  display: flex;
  height: 40px;
  align-items: center;
`;

const OrderTicketItemCopyButtonStyle = css`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  background: var(--surface-surface-disabled, #e8e8e8);

  color: var(--text-text-default, #1a1a1a);
  text-align: center;
  font-size: var(--font-size-font-size-5, 14px);
  font-style: normal;
  font-weight: var(--font-weight-font-weight-bold, 700);
  line-height: var(--line-height-font-height-5, 20px); /* 142.857% */
`;

const OrderTicketItemShareButtonStyle = css`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  background: var(--light-primary-100, #e3d6ff);

  color: var(--text-text-primary, #7800ff);
  text-align: center;

  /* Subhead3 */
  font-family: var(--font-family-Pretendard, Pretendard);
  font-size: var(--font-size-font-size-5, 14px);
  font-style: normal;
  font-weight: var(--font-weight-font-weight-bold, 700);
  line-height: var(--line-height-font-height-5, 20px); /* 142.857% */
`;
