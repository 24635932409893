import { useEffect, useState } from 'react';
import { css } from '@emotion/css';
import { TopNavigation } from '@src/components/TopNavigationBar';
import { BottomPurchaseBar } from '@src/components/BottomPurchaseBar';
import { useNavigate } from 'react-router-dom';
import { useGetProducts } from '@src/apis/queries';
import { Product, ProductMetaForDonation } from '@src/models/product';
import { usePostMessage } from '@src/hooks/usePostMessage';
import { DonationItem } from '@src/components/DonationItem';
import { usePurchase } from '@src/hooks/usePurchase';
import { useText } from '@src/hooks/useText';
import { Creator } from '@src/models/creator';
import { getCreatorInfoFromLocalStorage } from '@src/utils/util';
import { useModal } from '@src/hooks/useModal';
import { RollingBanner } from '@src/components/RollingBanner';
import { MockDonationText } from '@src/constants/text';

export const DonationPage = () => {
  const navigate = useNavigate();
  const { openModal } = useModal();
  const { updatePurchaseState } = usePurchase();
  const { title, actionTitle } = useText('donation');

  const { data: donationProducts } = useGetProducts({ paymentMethod: 'TossPaymentPg', productType: 'Donation' });

  const [products, setProducts] = useState<Product<'Donation', ProductMetaForDonation>[]>([]);
  const [creatorInfo, setCreatorInfo] = useState<Creator | null>();
  const [selectedProduct, setSelectedProduct] = useState<Product<'Donation', ProductMetaForDonation>>();
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);

  useEffect(() => {
    openModal();
  }, []);

  useEffect(() => {
    setCreatorInfo(getCreatorInfoFromLocalStorage());

    if (donationProducts?.success) {
      setProducts(donationProducts.data as Product<'Donation', ProductMetaForDonation>[]);
    }
  }, [donationProducts]);

  const handlePurchase = () => {
    if (selectedProduct) {
      selectedProduct.name = selectedProduct.name ? selectedProduct.name + (creatorInfo?.channelName || '') : undefined;
      updatePurchaseState({
        selectedProduct: selectedProduct,
        postMessageType: 'purchase',
        purchaseMessageDataType: 'donation',
      });

      navigate('/checkout');
    } else {
      alert('상품을 선택해주세요');
    }
  };

  const postMessageToParent = usePostMessage();
  const sendMessageToParent = () => {
    const closeMessage: PostMessageType<ActionMessage> = {
      type: 'action',
      data: {
        messageDataType: 'close',
        payload: { status: true },
      },
    };

    if (window.isFlutter()) postMessageToParent<ActionMessage>(closeMessage);
    else history.back();
  };

  return (
    <div>
      <TopNavigation title={title} onClickBack={sendMessageToParent} />
      <div className={DonationPageStyle}>
        <div className={TopRollingBannerStyle}>
          <RollingBanner bannerItems={MockDonationText} interval={1500} isRandom={true} />
        </div>
        <div className={ProductListStyle}>
          {products.map((product, index) => (
            <DonationItem
              key={index}
              index={index}
              data={product}
              selectedIndex={selectedIndex}
              isRecentlyDonated={index === 2}
              onClick={() => {
                setSelectedIndex(index);
                setSelectedProduct(product);
              }}
            />
          ))}
        </div>
        <div className={BottomInformationStyle}>
          <div className={InformationTitleStyle}>크리에이터 후원 안내</div>
          <div className={InformationContentStyle}>
            <p>
              • 크리에이터 후원하기는 <b>최소 100 츄로</b>부터 후원할 수 있어요.
            </p>
            <p>• 후원하신 츄로는 크리에이터님에게 전달되며 크리에이터님의 작품 활동에 큰 도움을 줄 수 있어요.</p>
            <p>• 자세한 정보는 홈페이지의 이용약관을 참고해주세요.</p>
          </div>
        </div>
      </div>

      <BottomPurchaseBar
        isSelected={selectedIndex >= 0}
        selectedProduct={selectedProduct}
        onClick={handlePurchase}
        actionTitle={actionTitle}
      />
    </div>
  );
};
const DonationPageStyle = css`
  width: 100%;
  align-items: center;
  padding-top: 48px;
  padding-bottom: 65px;
`;

const TopRollingBannerStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
`;

const ProductListStyle = css`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  gap: 8px;
  padding: 0px 16px;
  padding-bottom: 16px;
`;

const BottomInformationStyle = css`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: var(--light-gray-100, #f2f2f2);
`;

const InformationTitleStyle = css`
  width: 100%;
  padding: 16px;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  font-size: 14px;
`;

const InformationContentStyle = css`
  display: flex;
  width: 100%;
  padding: 0px 16px 16px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  word-break: keep-all;

  color: var(--light-gray-800, #515253);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
`;
