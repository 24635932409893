import { css } from '@emotion/css';
import { Accordion } from '@src/components/common/Accordion';
import { Button } from '@src/components/common/Button';
import { OrderInfo } from '@src/models/order-info';
import { ProductMetaForTicket } from '@src/models/product';
import { useState } from 'react';

interface PaymentSummaryProps {
  authId?: string | null;
  orderInfo?: OrderInfo<'Ticket', ProductMetaForTicket>;
  onCancel?: () => void;
}

export const PaymentSummary = ({ authId, orderInfo, onCancel }: PaymentSummaryProps) => {
  const isCancel = ['Paid'].includes(orderInfo!.orderState!) && authId;
  const [isExpanded, setIsExpanded] = useState(true);

  const handleToggle = () => setIsExpanded(!isExpanded);

  return (
    <Accordion title='결제 정보' isExpanded={isExpanded} onToggle={handleToggle}>
      <div className={PaymentSummaryContentStyle}>
        <div className={PaymentSummaryContentContactStyle}>
          <span>총 결제 금액</span>
          <p>{orderInfo?.totalPrice?.toLocaleString()}원</p>
        </div>
      </div>
      {isCancel && (
        <div className={PaymentSummaryContentFooterStyle}>
          <Button label='구매 취소하기' isPrimary={false} onClick={onCancel} />
        </div>
      )}
    </Accordion>
  );
};

const PaymentSummaryContentStyle = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  gap: 24px;
`;

const PaymentSummaryContentContactStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
  > span {
    color: var(--text-text-description, #555);

    /* Body2 */
    font-size: var(--font-size-font-size-5, 14px);
    font-style: normal;
    font-weight: var(--font-weight-font-weight-regular, 400);
    line-height: var(--line-height-font-height-5, 20px); /* 142.857% */
    letter-spacing: -0.1px;
  }

  > p {
    color: var(--text-text-default, #1a1a1a);

    /* Subhead1 */
    font-size: var(--font-size-font-size-3, 18px);
    font-style: normal;
    font-weight: var(--font-weight-font-weight-extrabold, 800);
    line-height: var(--line-height-font-height-3, 26px); /* 144.444% */
  }
`;

const PaymentSummaryContentFooterStyle = css`
  display: flex;
  width: 100%;
  padding-top: 24px;
  align-items: flex-end;
  gap: 8px;
  align-self: stretch;
`;
