import { useEffect, useRef, useState } from 'react';
import { css, keyframes } from '@emotion/css';
import { BoxInput } from '@src/components/common/BoxInput';

interface VerifyCodeInputProps {
  isNotValid: boolean;
  onResendCode: () => void; // 재전송 시 호출되는 함수
  onValuesChange: (combinedValue: string, isEdit: boolean) => void;
  onUpdateIsNotValid: (isNotValid: boolean) => void;
}

export const VerifyCodeInput = ({
  isNotValid,
  onValuesChange,
  onResendCode,
  onUpdateIsNotValid,
}: VerifyCodeInputProps) => {
  const [values, setValues] = useState(['', '', '', '']);
  const [timeLeft, setTimeLeft] = useState(180); // 3분 타이머 (180초)

  const inputRefs = useRef<HTMLInputElement[]>([]);

  useEffect(() => {
    setTimeout(() => {
      inputRefs.current[0].focus();
    }, 300);
  }, []);

  // 타이머 감소 로직
  useEffect(() => {
    if (timeLeft === 0) return; // 타이머가 0이면 멈춤
    const timerId = setInterval(() => {
      setTimeLeft((prev) => prev - 1);
    }, 1000);
    return () => clearInterval(timerId);
  }, [timeLeft]);

  // 값이 모두 채워졌을 때 부모 컴포넌트로 값 전달
  useEffect(() => {
    const combinedValue = values.join('');
    const isComplete = values.every((value) => value.length === 1);

    if (isComplete) {
      onValuesChange(combinedValue, false);
      inputRefs.current.map((input) => input.blur());
    } else {
      onValuesChange('', true);
    }
  }, [values]);

  // 입력 필드 값 변경 핸들러
  const handleChange = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;

    if (newValue.length === 4) {
      const newValueArray = newValue.split('');
      setValues(newValueArray); // 모든 입력 필드에 값을 분배

      // 마지막 필드로 포커스를 이동
      if (inputRefs.current[3]) {
        inputRefs.current[3].focus();
      }
      return;
    }

    if (newValue.length <= 1) {
      const newValues = [...values];
      newValues[index] = newValue;
      setValues(newValues);

      // 다음 인풋으로 포커스 이동
      if (newValue && index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus();
      }

      return;
    }
  };

  // 인증번호 재전송 시 초기화
  const handleResend = () => {
    setValues(['', '', '', '']);
    setTimeLeft(180); // 타이머 리셋
    inputRefs.current[0].focus(); // 첫 번째 인풋으로 포커스
    onResendCode(); // 부모 컴포넌트의 재전송 함수 호출
    onUpdateIsNotValid(false);
  };

  // 타이머 형식 변환 함수
  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  return (
    <>
      <div className={VerifyCodeInputStyle}>
        <div className={InputGroupStyle}>
          {values.map((value, index) => (
            <BoxInput
              key={index}
              ref={(el) => (inputRefs.current[index] = el!)}
              width='48px'
              maxLength={1}
              inputType='number'
              value={value}
              onChange={handleChange(index)}
            />
          ))}
        </div>
        {isNotValid && <div className={InputNotValidStyle}>인증번호가 맞지 않아요.</div>}
        <div className={TimerStyle}>{formatTime(timeLeft)}</div>
        <button className={ResendButtonStyle} onClick={handleResend}>
          인증번호 다시 받기
        </button>
      </div>
    </>
  );
};

const slideUp = keyframes`
  from {
    transform: translateY(20%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const VerifyCodeInputStyle = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  align-self: stretch;
`;

const InputGroupStyle = css`
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
`;

const InputNotValidStyle = css`
  display: flex;
  align-items: center;
  gap: 16px;
  animation: ${slideUp} 0.5s ease-in-out; // 애니메이션 추가

  color: var(--text-text-error, #f5495b);
  text-align: center;

  /* Subhead2 */
  font-size: var(--font-size-font-size-4, 16px);
  font-style: normal;
  font-weight: var(--font-weight-font-weight-bold, 700);
  line-height: var(--line-height-font-height-4, 22px); /* 137.5% */
`;

const TimerStyle = css`
  color: var(--text-text-primary, #7800ff);
  text-align: right;

  /* Subhead2 */
  font-size: var(--font-size-font-size-4, 16px);
  font-style: normal;
  font-weight: var(--font-weight-font-weight-bold, 700);
  line-height: var(--line-height-font-height-4, 22px); /* 137.5% */
`;

const ResendButtonStyle = css`
  border-radius: var(--button-S-32, 12px);
  background: var(--surface-surface-disabled, #e8e8e8);
  display: flex;
  height: 32px;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }

  color: var(--text-text-description, #555);

  /* Subhead3 */
  font-family: var(--font-family-Pretendard, Pretendard);
  font-size: var(--font-size-font-size-5, 14px);
  font-style: normal;
  font-weight: var(--font-weight-font-weight-bold, 700);
  line-height: var(--line-height-font-height-5, 20px); /* 142.857% */
`;
