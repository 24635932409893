import { useQuery } from '@tanstack/react-query';
import {
  getOrderHistory,
  getOrderInfo,
  getProducts,
  getTicketInfo,
  getTicketInfoByOrderId,
  getUserInfo,
} from '@src/apis/index';

export const useGetUserInfo = () => {
  return useQuery({ queryKey: ['getUserInfo'], queryFn: getUserInfo });
};

export const useGetProducts = (queryParam?: Record<string, string>) => {
  return useQuery({ queryKey: ['getProducts', queryParam], queryFn: getProducts });
};

export const useGetOrderInfo = (orderId: string) => {
  return useQuery({ queryKey: ['getOrderInfo', orderId], queryFn: () => getOrderInfo(orderId!), enabled: !!orderId });
};

export const useGetTicketInfo = (ticketId: string) => {
  return useQuery({
    queryKey: ['getTicketInfo', ticketId],
    queryFn: () => getTicketInfo(ticketId!),
    enabled: !!ticketId,
  });
};

export const useGetTicketInfoByOrderId = (orderId: string) => {
  return useQuery({
    queryKey: ['getTicketInfoByOrderId', orderId],
    queryFn: () => getTicketInfoByOrderId(orderId!),
    enabled: !!orderId,
  });
};

export const useGetOrderHistory = (queryParam?: Record<string, string>) => {
  return useQuery({ queryKey: ['getOrderHistory', queryParam], queryFn: getOrderHistory });
};
