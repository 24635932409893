import ReactGA from 'react-ga4';
import mixpanel from 'mixpanel-browser';

interface TrackingData {
  eventName: string;
  properties?: { [key: string]: unknown };
}

const GA_MEASUREMENT_ID = import.meta.env.VITE_GA_MEASUREMENT_ID;
const VITE_MIXPANEL_WEB_PROJECT_TOKEN = import.meta.env.VITE_MIXPANEL_WEB_PROJECT_TOKEN;
const IS_PRODUCTION = import.meta.env.MODE === 'production';

const camelToSnakeCase = (str: string): string => {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
};

const convertKeysToSnakeCase = (obj: { [key: string]: unknown }) => {
  return Object.keys(obj).reduce(
    (acc, key) => {
      const snakeKey = camelToSnakeCase(key);
      acc[snakeKey] = obj[key];
      return acc;
    },
    {} as { [key: string]: unknown },
  );
};

export const initTracker = () => {
  if (IS_PRODUCTION) {
    ReactGA.initialize(GA_MEASUREMENT_ID);
  }
  mixpanel.init(VITE_MIXPANEL_WEB_PROJECT_TOKEN, { debug: false, track_pageview: true, persistence: 'localStorage' });
};

export const trackEvent = ({ eventName, properties }: TrackingData) => {
  let transformedProperties = properties;

  // properties가 존재할 경우, camelCase -> snake_case 변환
  if (properties) {
    transformedProperties = convertKeysToSnakeCase(properties);
  }

  if (IS_PRODUCTION) {
    ReactGA.event({ action: eventName, category: eventName, label: JSON.stringify(properties) });
  }

  mixpanel.track(eventName, transformedProperties);
};

export const identifyUser = (userId: string) => {
  mixpanel.identify(userId);
};
