import { apiRequest } from './api-request';
import { Product, ProductMetaType, ProductType } from '@src/models/product';
import { QueryFunctionContext } from '@tanstack/react-query';
import { OrderDetail, OrderInfo } from '@src/models/order-info';
import { RequestOrderCallback, RequestOrderInfo, RequestOrderInfoForAnonymous } from '@src/models/request';
import { UserInfo } from '@src/models/user-info';
import { OrderSuccessCallback, OrderFailCallback } from '@src/models/order-callback';
import { VerifySuccess } from '@src/models/verify';
import { Ticket } from '@src/models/ticket';

export const getUserInfo = async () => {
  return apiRequest<UserInfo>('get', '/api/me', '1.0');
};

export const getProducts = async (queryObject: QueryFunctionContext) => {
  return apiRequest<Product<ProductType, ProductMetaType>>('get', '/pay/products', '1.0', queryObject);
};

export const getOrderHistory = async (queryObject: QueryFunctionContext) => {
  return apiRequest<OrderInfo<ProductType, ProductMetaType>[]>('get', '/pay/orders/history', '1.1', queryObject);
};

export const getOrderInfo = async (orderId: string) => {
  return apiRequest<OrderDetail<ProductType, ProductMetaType>>('get', `/pay/orders/${orderId}`, '1.0');
};

export const getTicketInfo = async (ticketId: string) => {
  return apiRequest<Ticket>('get', `/tickets/${ticketId}`, '1.0');
};

export const getTicketInfoByOrderId = async (orderId: string) => {
  return apiRequest<Ticket[]>('get', `/tickets/codes/${orderId}`, '1.0');
};

export const postAuthRefresh = async (body: AuthToken) => {
  return apiRequest<AuthToken, AuthToken>('post', '/auth/refresh', '1.0', body);
};

export const postOrderInfo = async (body: RequestOrderInfo) => {
  return apiRequest<RequestOrderInfo, OrderInfo<ProductType, ProductMetaType>>('post', '/pay/orders', '1.0', body);
};

export const postOrderInfoCancel = async (orderId: string, authId: string) => {
  return apiRequest<{ success: boolean }>('post', `/pay/orders/${orderId}/cancel?authId=${authId}`, '1.0');
};

export const postOrderInfoForAnonymous = async (body: RequestOrderInfoForAnonymous) => {
  return apiRequest<RequestOrderInfoForAnonymous, OrderInfo<ProductType, ProductMetaType>>(
    'post',
    '/pay/orders/anonymous',
    '1.0',
    body,
  );
};

export const postOrderCallback = async (body: RequestOrderCallback) => {
  return apiRequest<RequestOrderCallback, OrderSuccessCallback | OrderFailCallback>(
    'post',
    '/pay/callback/tosspay',
    '1.0',
    body,
  );
};

export const postVerifyCodeRequest = async (body: { target: string }) => {
  return apiRequest<{ target: string }, { success: boolean }>(
    'post',
    '/verifications/treasure-ticket/request',
    '1.0',
    body,
    true,
  );
};

export const postVerifyCodeCheck = async (body: { verificationTarget: string; code: string }) => {
  return apiRequest<{ verificationTarget: string; code: string }, VerifySuccess>(
    'post',
    '/verifications/treasure-ticket/verify',
    '1.0',
    body,
    true,
  );
};
