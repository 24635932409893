import { PaymentOption } from '@src/components/features/Payment/PaymentOption';
import { PaymentOrdererInfo } from '@src/components/features/Payment/PaymentOrdererInfo';
import { PaymentSummary } from '@src/components/features/Payment/PaymentSummary';
import { PaymentNotice } from '@src/components/features/Payment/PaymentNotice';
import { TopNavigation } from '@src/components/TopNavigationBar';
import { css } from '@emotion/css';
import { Divider } from '@src/components/common/Divider';
import { PaymentButton } from '@src/components/features/Payment/PaymentButton';
import { useState } from 'react';

export const PaymentPage = () => {
  const [isChecked, setIsChecked] = useState(false);
  const updateIsChecked = () => setIsChecked(!isChecked);

  return (
    <>
      <TopNavigation title='결제하기' />
      <div className={PaymentPageStyle}>
        <div className={PaymentPageContentStyle}>
          <PaymentOption />
          <Divider />
          <PaymentOrdererInfo />
          <Divider />
          <PaymentSummary />
          <Divider />
          <PaymentNotice isChecked={isChecked} updateIsChecked={updateIsChecked} />
        </div>
        <PaymentButton isEnable={isChecked} title='58,000원 결제하기' />
      </div>
    </>
  );
};

const PaymentPageStyle = css`
  width: 100%;
  align-items: center;
  padding-top: 48px;
  padding-bottom: 98px;
`;

const PaymentPageContentStyle = css`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
`;
