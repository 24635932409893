import { css } from '@emotion/css';
import { OrderStatusTexts } from '@src/constants/text';
import { OrderState } from '@src/models/order-info';

interface OrderStateLabelProps {
  orderState: OrderState;
}

export const OrderStateLabel = ({ orderState }: OrderStateLabelProps) => {
  return <div className={OrderItemStateStyle(orderState)}>{OrderStatusTexts[orderState]}</div>;
};

const OrderItemStateStyle = (orderState: OrderState) => css`
  display: flex;
  height: 24px;
  padding: 0px 8px;
  align-items: center;
  border-radius: var(--tag-rectangle, 4px);

  background: ${orderState === 'Paid' ? '#f4f0ff' : '#F5F5F5'};
  color: ${orderState === 'Paid' ? '#7800ff' : '#555555'};

  /* Subhead3 */
  font-size: var(--font-size-font-size-4, 14px);
  font-style: normal;
  font-weight: var(--font-weight-font-weight-bold, 700);
  line-height: var(--line-height-font-height-4, 20px); /* 142.857% */
`;
