import { css } from '@emotion/css';
import { useGetOrderHistory } from '@src/apis/queries';
import { TopNavigation } from '@src/components/TopNavigationBar';
import { OrderGroup } from '@src/components/features/Order/OrderGroup';
import { useLoading } from '@src/hooks/useLoading';
import { OrderInfo } from '@src/models/order-info';
import { ProductMetaForTicket } from '@src/models/product';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import EmptyDagoo from '@src/assets/dagoo-empty.svg';
import { saveToSessionStorage } from '@src/utils/util';

export const OrdersPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const authId = searchParams.get('authId');
  const orderId = searchParams.get('orderId');

  const { data: ordersData } = useGetOrderHistory({ authId: authId! });
  const { hideLoading } = useLoading();

  const [orders, setOrders] = useState<OrderInfo<'Ticket', ProductMetaForTicket>[]>([]);

  useEffect(() => {
    if (authId) {
      saveToSessionStorage<string>('authId', authId);
    }
  }, [authId]);

  useEffect(() => {
    if (ordersData?.success) {
      setOrders(ordersData.data as OrderInfo<'Ticket', ProductMetaForTicket>[]);
    }
  }, [ordersData]);

  useEffect(() => {
    const hasRedirected = sessionStorage.getItem('hasRedirectedToOrderDetail');
    if (orderId && !hasRedirected) {
      setTimeout(() => {
        sessionStorage.setItem('hasRedirectedToOrderDetail', 'true');
        hideLoading();
        navigate(`/orders/${orderId}`);
      }, 1000);
    }
  }, [orderId]);

  const handleClose = () => {
    sessionStorage.removeItem('hasRedirectedToOrderDetail');
    navigate('/ticket', { replace: true });
  };

  if (orders.length === 0)
    return (
      <>
        <TopNavigation title='구매내역' isClose onClickClose={handleClose} />
        <div className={NoneOrderPageStyle}>
          <img src={EmptyDagoo} alt='empty_dagoo' />
          <span>구매한 정보가 없어요</span>
        </div>
      </>
    );

  return (
    <>
      <TopNavigation title='구매내역' isClose onClickClose={handleClose} />
      <div className={OrdersPageStyle}>
        <OrderGroup orders={orders} />
      </div>
    </>
  );
};

const OrdersPageStyle = css`
  width: 100%;
  align-items: center;
  padding: 0px 16px;
  padding-top: 64px;
  padding-bottom: 16px;
  gap: 40px;
  background: #f5f5f5;
`;

const NoneOrderPageStyle = css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 16px;
  > span {
    color: var(--text-text-default, #1a1a1a);

    /* Subhead1 */
    font-size: var(--font-size-font-size-3, 18px);
    font-style: normal;
    font-weight: var(--font-weight-font-weight-extrabold, 800);
    line-height: var(--line-height-font-height-3, 26px); /* 144.444% */
  }
`;
