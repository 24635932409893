import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { OrderInfo } from '@src/models/order-info';
import { Product, ProductMetaType, ProductType } from '@src/models/product';

interface BottomPurchaseBarProps {
  selectedProduct?: Product<ProductType, ProductMetaType>;
  orderInfo?: OrderInfo<ProductType, ProductMetaType>;
  isSelected?: boolean;
  actionTitle: string;
  onClick?: () => void;
}

export const BottomPurchaseBar = (props: BottomPurchaseBarProps) => {
  return (
    <div className={BottomPurchaseStyle} onClick={() => props.isSelected && props.onClick && props.onClick()}>
      {props.selectedProduct && (
        <PurchaseButtonStyle isSelected={props.isSelected}>
          {props.selectedProduct.name} {props.actionTitle}
        </PurchaseButtonStyle>
      )}

      {props.orderInfo && (
        <PurchaseButtonStyle isSelected={props.isSelected}>
          {props.orderInfo.totalPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원 결제하기
        </PurchaseButtonStyle>
      )}
    </div>
  );
};

const BottomPurchaseStyle = css`
  z-index: 999;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 460px;
  height: 72px;
  padding: 8px 16px;
  background-color: transparent;
  bottom: 0;
`;

const PurchaseButtonStyle = styled.div<{ isSelected?: boolean }>`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: ${(props) => (props.isSelected ? 'var(--light-primary-500, #7800FF);' : '#d6d6d6;')};

  color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
`;
