import { useEffect, useRef, useState } from 'react';
import { css } from '@emotion/css';
import { BoxInput } from '@src/components/common/BoxInput';

interface NumberInputProps {
  onValuesChange: (combinedValue: string, isEdit: boolean) => void;
}

export const PhoneNumberInput = ({ onValuesChange }: NumberInputProps) => {
  const [firstValue, setFirstValue] = useState('');
  const [secondValue, setSecondValue] = useState('');
  const [thirdValue, setThirdValue] = useState('');

  const firstInputRef = useRef<HTMLInputElement>(null);
  const secondInputRef = useRef<HTMLInputElement>(null);
  const thirdInputRef = useRef<HTMLInputElement>(null);

  const handleFirstChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const maxLength = firstInputRef.current!.maxLength;
    const value = e.target.value;

    if (value.length <= maxLength) setFirstValue(value);

    if (value.length === maxLength && secondInputRef.current) {
      secondInputRef.current.focus();
    }
  };

  const handleSecondChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const maxLength = secondInputRef.current!.maxLength;
    const value = e.target.value;

    if (value.length <= maxLength) setSecondValue(value);

    if (value.length === maxLength && thirdInputRef.current) {
      thirdInputRef.current.focus();
    }
  };

  const handleThirdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const maxLength = secondInputRef.current!.maxLength;
    const value = e.target.value;

    if (value.length <= maxLength) setThirdValue(value);
  };

  // 모든 값이 각 maxLength에 도달했을 때만 부모에게 전달
  useEffect(() => {
    const firstMaxLength = firstInputRef.current!.maxLength;
    const secondMaxLength = secondInputRef.current!.maxLength;
    const thirdMaxLength = thirdInputRef.current!.maxLength;

    const isValidPhoneNumber = (phoneNumber: string) => {
      const phonePattern = /^010-\d{4}-\d{4}$/; // 휴대폰 번호 패턴: 010-1234-5678
      return phonePattern.test(phoneNumber);
    };

    const combinedValue = `${firstValue}-${secondValue}-${thirdValue}`;

    if (
      firstValue.length === firstMaxLength &&
      secondValue.length === secondMaxLength &&
      thirdValue.length === thirdMaxLength
    ) {
      if (isValidPhoneNumber(combinedValue)) {
        onValuesChange(`${firstValue}-${secondValue}-${thirdValue}`, false);
      } else {
        alert('올바른 휴대폰 번호를 입력해주세요.');
        setFirstValue('');
        setSecondValue('');
        setThirdValue('');
        firstInputRef.current?.focus();
      }
    } else {
      onValuesChange('', true);
    }
  }, [firstValue, secondValue, thirdValue, onValuesChange]);

  return (
    <div className={NumberInputStyle}>
      <div className={NumberInputGroupStyle}>
        <BoxInput
          ref={firstInputRef}
          maxLength={3}
          inputType='number'
          placeholder='010'
          value={firstValue}
          onChange={handleFirstChange}
        />
        <BoxInput
          ref={secondInputRef}
          maxLength={4}
          inputType='number'
          placeholder='0000'
          value={secondValue}
          onChange={handleSecondChange}
        />
        <BoxInput
          ref={thirdInputRef}
          maxLength={4}
          inputType='number'
          placeholder='0000'
          value={thirdValue}
          onChange={handleThirdChange}
        />
      </div>
    </div>
  );
};

const NumberInputStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  align-items: center;
`;

const NumberInputGroupStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;
