import { css } from '@emotion/css';

export const Divider = () => {
  return (
    <div className={DividerStyle}>
      <hr />
    </div>
  );
};

const DividerStyle = css`
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  height: 56px;
  display: flex;
  padding: 24px 0px;
  flex-direction: column;
  align-items: flex-start;

  > hr {
    width: 100%; /* 부모의 너비를 가득 채우기 */
    height: var(--spacing-8, 8px);
    background: var(--border-border-default, #e8e8e8);
  }
`;
