import React, { ReactNode, useRef, useEffect, useState } from 'react';
import { css } from '@emotion/css';
import { Chevron } from '@src/components/common/Chevron';

interface AccordionProps {
  title: string;
  children: ReactNode;
  isExpanded: boolean;
  onToggle: () => void;
}

export const Accordion: React.FC<AccordionProps> = ({ title, children, isExpanded, onToggle }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [currentHeight, setCurrentHeight] = useState<string>('10000px');
  const [maxHeight, setMaxHeight] = useState<string>(currentHeight);

  useEffect(() => {
    if (contentRef.current) setMaxHeight(`${contentRef.current.scrollHeight}px`);
  }, []);

  useEffect(() => {
    if (isExpanded) {
      setCurrentHeight(maxHeight);
    } else {
      setCurrentHeight('0px');
    }
  }, [isExpanded, maxHeight]);

  return (
    <div className={accordionStyle}>
      <div className={accordionHeaderStyle} onClick={onToggle}>
        <p>{title}</p>
        <Chevron isExpanded={isExpanded} toggleChevron={onToggle} />
      </div>
      <div ref={contentRef} className={accordionContentStyle(currentHeight)}>
        {children}
      </div>
    </div>
  );
};

const accordionStyle = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

const accordionHeaderStyle = css`
  display: flex;
  height: 40px;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
  align-self: stretch;
  cursor: pointer;

  > p {
    color: var(--text-text-default, #1a1a1a);
    font-size: var(--font-size-font-size-3, 18px);
    font-weight: var(--font-weight-font-weight-extrabold, 800);
    line-height: var(--line-height-font-height-3, 26px);
  }
`;

const accordionContentStyle = (currentHeight: string) => css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  max-height: ${currentHeight};
`;
