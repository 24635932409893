import { VerificationContext } from '@src/providers/verification';
import { useContext } from 'react';

export const useVerification = () => {
  const context = useContext(VerificationContext);

  if (!context) {
    throw new Error('useVerification must be used within a VerificationProvider');
  }
  return context;
};
