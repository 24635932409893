import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { CheckoutPage } from '@src/pages/Checkout';
import { SuccessPage } from '@src/pages/Success';
import { FailPage } from '@src/pages/Fail';
import { ShopPage } from '@src/pages/Shop';
import { HomePage } from '@src/pages/Home';
import { DonationPage } from '@src/pages/Donation';
import { useGetUserInfo } from '@src/apis/queries';
import { NotFound } from '@src/pages/NotFound';
import { usePostMessage } from '@src/hooks/usePostMessage';
import { initializeFlutterInterface } from '@src/utils/flutter-interface';
import { Global } from '@emotion/react';
import { globalStyle } from '@src/global.style';
import { useEffect } from 'react';
import { ProjectPage } from './pages/Project';
import { Modal } from '@src/components/Modal';
import { TicketPage } from '@src/pages/Ticket';
import { VerifyPage } from './pages/Verify';
import { LoadingSpinner } from './components/common/LoadingSpinner';
import { PaymentPage } from './pages/Payment';
import { OrderDetailPage } from './pages/OrderDetail';
import { OrdersPage } from './pages/Orders';
import { TicketRegister } from './pages/TicketRegister';
import { initTracker } from './utils/analytics';

const router = createBrowserRouter([
  { path: '/', element: <HomePage /> },
  { path: '/ticket', element: <TicketPage /> },
  { path: '/ticket/register', element: <TicketRegister /> },
  { path: '/verify', element: <VerifyPage /> },
  { path: '/payment', element: <PaymentPage /> },
  { path: '/orders', element: <OrdersPage /> },
  { path: '/orders/:id', element: <OrderDetailPage /> },
  { path: '/shop', element: <ShopPage /> },
  { path: '/donation', element: <DonationPage /> },
  { path: '/project', element: <ProjectPage /> },
  { path: '/checkout', element: <CheckoutPage /> },
  { path: '/success', element: <SuccessPage /> },
  { path: '/fail', element: <FailPage /> },
  { path: '*', element: <NotFound /> },
]);

initializeFlutterInterface();
const { Kakao } = window;

export const App = () => {
  const { refetch } = useGetUserInfo();

  const setUserInfo = async () => {
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');

    if (accessToken && refreshToken) {
      const result = await refetch();

      if (result.isSuccess && result.data) {
        localStorage.setItem('userInfo', JSON.stringify(result.data));
      }
    }
  };

  const handleReceiveMessage = async (message: PostMessageType<MessageDataType>) => {
    if (message.type === 'auth') {
      const { accessToken, refreshToken } = message.data.payload as AuthMessagePayload;

      if (accessToken) localStorage.setItem('accessToken', accessToken);
      if (refreshToken) localStorage.setItem('refreshToken', refreshToken);

      await setUserInfo();
    }

    if (message.type === 'purchase') {
      const { projectId } = message.data.payload as PurchaseMessagePayload;

      if (projectId) localStorage.setItem('projectId', projectId);
    }
  };

  usePostMessage(import.meta.env.VITE_POST_MESSAGE_TARGET_URL, handleReceiveMessage);

  useEffect(() => {
    initTracker();
    setUserInfo();
    Kakao.cleanup();
    Kakao.init(import.meta.env.VITE_KAKAO_APP_KEY);
  }, []);

  return (
    <>
      <Global styles={globalStyle} />
      <RouterProvider router={router} />
      <Modal />
      <LoadingSpinner />
    </>
  );
};
