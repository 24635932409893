import { VerifySuccess } from '@src/models/verify';
import React, { createContext, useState } from 'react';

// 공유할 상태의 타입 정의
interface VerificationContextType {
  phoneNumber: string;
  isVerified: boolean;
  verifier: string;
  verifySuccess: VerifySuccess | null;
  setPhoneNumber: (phoneNumber: string) => void;
  setIsVerified: (isVerified: boolean) => void;
  setVerifier: (verifier: string) => void;
  setVerifySuccess: (verifySuccess: VerifySuccess | null) => void;
}

const initialState: VerificationContextType = {
  phoneNumber: '',
  isVerified: false,
  verifier: '',
  verifySuccess: null,
  setPhoneNumber: () => {},
  setIsVerified: () => {},
  setVerifier: () => {},
  setVerifySuccess: () => {},
};

const VerificationContext = createContext<VerificationContextType>(initialState);

const VerificationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  const [verifier, setVerifier] = useState('');
  const [verifySuccess, setVerifySuccess] = useState<VerifySuccess | null>(null);

  return (
    <VerificationContext.Provider
      value={{
        phoneNumber,
        isVerified,
        verifier,
        verifySuccess,
        setPhoneNumber,
        setIsVerified,
        setVerifier,
        setVerifySuccess,
      }}
    >
      {children}
    </VerificationContext.Provider>
  );
};

export { VerificationContext, VerificationProvider };
