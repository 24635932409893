import { useEffect, useRef, useState } from 'react';
import { PaymentWidgetInstance, loadPaymentWidget } from '@tosspayments/payment-widget-sdk';
import { nanoid } from 'nanoid';
import { useQuery } from '@tanstack/react-query';
import { usePostOrderInfo, usePostOrderInfoForAnonymous } from '@src/apis/mutations';
import { OrderInfo } from '@src/models/order-info';
import { TopNavigation } from '@src/components/TopNavigationBar';
import { BottomPurchaseBar } from '@src/components/BottomPurchaseBar';
import {
  convertPhoneNumber,
  getCreatorInfoFromLocalStorage,
  getUserInfoFromLocalStorage,
  saveToSessionStorage,
} from '@src/utils/util';
import { usePurchase } from '@src/hooks/usePurchase';
import { useText } from '@src/hooks/useText';
import { usePostMessage } from '@src/hooks/usePostMessage';
import { ProductMetaType, ProductType } from '@src/models/product';
import { useVerification } from '@src/hooks/useVerification';
import { PurchaseState } from '@src/models/purchase';
import { VerifySuccess } from '@src/models/verify';

const selector = '#payment-widget';

// TODO: clientKey는 개발자센터의 결제위젯 연동 키 > 클라이언트 키로 바꾸세요.
// TODO: customerKey는 구매자와 1:1 관계로 무작위한 고유값을 생성하세요.
// @docs https://docs.tosspayments.com/reference/widget-sdk#sdk-설치-및-초기화
const clientKey = import.meta.env.VITE_REALWORLD_TOSS_PAYMENT_CLIENT_KEY;
const customerKey = nanoid();

export const CheckoutPage = () => {
  const { isVerified, phoneNumber, verifier, verifySuccess } = useVerification();
  const { purchaseState } = usePurchase();
  const { title, actionTitle } = useText(purchaseState.purchaseMessageDataType);

  const { data: paymentWidget } = usePaymentWidget(clientKey, customerKey);
  const paymentMethodsWidgetRef = useRef<ReturnType<PaymentWidgetInstance['renderPaymentMethods']> | null>(null);
  const [orderInfo, setOrderInfo] = useState<OrderInfo<ProductType, ProductMetaType>>();

  const { mutateAsync: postOrderInfo } = usePostOrderInfo();
  const { mutateAsync: postOrderInfoForAnonymous } = usePostOrderInfoForAnonymous();

  const creatorInfo = getCreatorInfoFromLocalStorage();

  useEffect(() => {
    async function createOrderInfo() {
      const isTicket = purchaseState.purchaseMessageDataType === 'ticket';

      if (isTicket) {
        const selectedTicketProducts = purchaseState.selectedTicketProducts;
        if (selectedTicketProducts && selectedTicketProducts.length > 0 && isVerified) {
          const result = await postOrderInfoForAnonymous({
            orderName: purchaseState.orderName,
            authId: verifySuccess!.id,
            userName: verifier,
            productQuantityList: selectedTicketProducts,
          });
          setOrderInfo(result.data as OrderInfo<ProductType, ProductMetaType>);
        }
      } else {
        const selectedProduct = purchaseState.selectedProduct;
        if (selectedProduct.id) {
          const result = await postOrderInfo({ productIdList: [selectedProduct.id] });
          setOrderInfo(result.data as OrderInfo<ProductType, ProductMetaType>);
        }
      }
    }
    createOrderInfo();
  }, []);

  useEffect(() => {
    if (paymentWidget == null) return;

    // ------  결제 UI 렌더링 ------
    // @docs https://docs.tosspayments.com/reference/widget-sdk#renderpaymentmethods선택자-결제-금액-옵션
    const paymentMethodsWidget = paymentWidget.renderPaymentMethods(
      selector,
      { value: orderInfo?.totalPrice ?? 0 },
      { variantKey: 'DEFAULT' },
    );

    // ------  이용약관 UI 렌더링 ------
    // @docs https://docs.tosspayments.com/reference/widget-sdk#renderagreement선택자-옵션
    paymentWidget.renderAgreement('#agreement', { variantKey: 'AGREEMENT' });

    paymentMethodsWidgetRef.current = paymentMethodsWidget;
  }, [paymentWidget, orderInfo]);

  useEffect(() => {
    const paymentMethodsWidget = paymentMethodsWidgetRef.current;
    if (paymentMethodsWidget == null) return;

    // ------ 금액 업데이트 ------
    // @docs https://docs.tosspayments.com/reference/widget-sdk#updateamount결제-금액
    paymentMethodsWidget.updateAmount(orderInfo?.totalPrice ?? 0);
  }, [orderInfo]);

  const handlePurchase = async () => {
    const isTicket = purchaseState.purchaseMessageDataType === 'ticket';
    const orderId = orderInfo?.orderId;
    const orderName =
      purchaseState.purchaseMessageDataType === 'donation'
        ? orderInfo?.orderProductList![0].productName + ' | ' + creatorInfo?.channelName + ' | ' + creatorInfo?.id
        : orderInfo?.orderProductList![0].productName;

    const userInfo = getUserInfoFromLocalStorage();
    const userName = isTicket ? verifier : userInfo?.name;
    const userMail = isTicket ? '' : userInfo?.email;
    const userPhone = isTicket
      ? convertPhoneNumber(phoneNumber)
      : userInfo?.phone && convertPhoneNumber(userInfo?.phone);

    // 필수 정보 누락 체크
    if (!orderId || !orderName || !userName) {
      console.error('필수 결제 정보가 누락되었습니다.');
      return; // 필수 정보가 없으면 함수 종료
    }

    try {
      saveToSessionStorage<PurchaseState>('purchaseState', purchaseState);
      saveToSessionStorage<VerifySuccess>('verifySuccess', verifySuccess!);
      // ------ '결제하기' 버튼 누르면 결제창 띄우기 ------
      // @docs https://docs.tosspayments.com/reference/widget-sdk#requestpayment결제-정보
      await paymentWidget?.requestPayment({
        orderId: orderId,
        orderName: orderName,
        customerName: userName,
        customerEmail: userMail,
        customerMobilePhone: userPhone,
        successUrl: `${window.location.origin}/success`,
        failUrl: `${window.location.origin}/fail`,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const postMessageToParent = usePostMessage();
  const sendMessageToParent = () => {
    const closeMessage: PostMessageType<ActionMessage> = {
      type: 'action',
      data: {
        messageDataType: 'close',
        payload: { status: true },
      },
    };

    if (window.isFlutter()) postMessageToParent<ActionMessage>(closeMessage);
    else window.close();
  };

  const handleClickBack = () => {
    if (purchaseState.purchaseMessageDataType === 'project') sendMessageToParent();
    else history.back();
  };

  return (
    <>
      <TopNavigation title={title} onClickBack={handleClickBack} />
      <div className='wrapper' style={{ paddingTop: '48px', paddingBottom: '68px' }}>
        <div className='box_section'>
          <div id='payment-widget' />
          <div id='agreement' />
          <div style={{ paddingLeft: '24px' }}></div>
          <div className='result wrapper'></div>
        </div>
      </div>
      <BottomPurchaseBar isSelected={true} orderInfo={orderInfo} onClick={handlePurchase} actionTitle={actionTitle} />
    </>
  );
};

function usePaymentWidget(clientKey: string, customerKey: string) {
  return useQuery({
    queryKey: ['payment-widget', clientKey, customerKey],
    queryFn: () => {
      // ------  결제위젯 초기화 ------
      // @docs https://docs.tosspayments.com/reference/widget-sdk#sdk-설치-및-초기화
      return loadPaymentWidget(clientKey, customerKey);
    },
  });
}
