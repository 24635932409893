export const kakaoShare = (title: string, code: string, codeTitle: string, codeDesc: string, url: string) => {
  if (window.Kakao) {
    const kakao = window.Kakao;

    kakao.Share.sendCustom({
      templateId: 111622,
      templateArgs: {
        title: title,
        code: code,
        codeTitle: codeTitle,
        codeDesc: codeDesc,
        path: url,
      },
    });
  }
};
