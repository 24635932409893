import React, { createContext, useState, ReactNode } from 'react';

interface ModalState {
  isOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
}

const initialModalState: ModalState = {
  isOpen: false,
  openModal: () => {},
  closeModal: () => {},
};

const ModalContext = createContext<ModalState>(initialModalState);

const ModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isOpen, setIsShown] = useState(false);

  const openModal = () => setIsShown(true);
  const closeModal = () => setIsShown(false);

  return <ModalContext.Provider value={{ isOpen, openModal, closeModal }}>{children}</ModalContext.Provider>;
};

export { ModalContext, ModalProvider };
