import { useMutation } from '@tanstack/react-query';
import {
  postAuthRefresh,
  postOrderCallback,
  postOrderInfo,
  postOrderInfoCancel,
  postOrderInfoForAnonymous,
  postVerifyCodeCheck,
  postVerifyCodeRequest,
} from '@src/apis/index';

export const usePostOrderInfo = () => {
  return useMutation({
    mutationKey: ['postOrderInfo'],
    mutationFn: postOrderInfo,
  });
};

export const usePostOrderInfoCancel = (orderId: string, authId: string) => {
  return useMutation({
    mutationKey: ['postOrderInfoCancel'],
    mutationFn: () => postOrderInfoCancel(orderId, authId),
  });
};

export const usePostOrderInfoForAnonymous = () => {
  return useMutation({
    mutationKey: ['postOrderInfoForAnonymous'],
    mutationFn: postOrderInfoForAnonymous,
  });
};

export const usePostOrderCallback = () => {
  return useMutation({
    mutationKey: ['postOrderCallback'],
    mutationFn: postOrderCallback,
  });
};

export const usePostAuthRefresh = () => {
  return useMutation({
    mutationKey: ['postAuthRefresh'],
    mutationFn: postAuthRefresh,
  });
};

export const usePostVerifyCodeRequest = () => {
  return useMutation({
    mutationKey: ['postVerifyCodeRequest'],
    mutationFn: postVerifyCodeRequest,
  });
};

export const usePostVerifyCodeCheck = () => {
  return useMutation({
    mutationKey: ['postVerifyCodeCheck'],
    mutationFn: postVerifyCodeCheck,
  });
};
