import { css } from '@emotion/css';
import styled from '@emotion/styled';
import IconChurros from '@src/assets/icon-churros.svg';
import IconCircleCheckboxOff from '@src/assets/icon-circle-checkbox-off.svg';
import IconCircleCheckboxOn from '@src/assets/icon-circle-checkbox-on.svg';
import { Product, ProductMetaForPoint, ProductMetaType, ProductType } from '@src/models/product';
import { Img } from 'react-image';
import { LoadingSpinner } from '@src/components/common/LoadingSpinner';

interface ProductItemProps {
  index: number;
  selectedIndex: number;
  data: Product<ProductType, ProductMetaType>;
  onClick?: () => void;
}

export const ProductItem = (props: ProductItemProps) => {
  const isSelected = props.index === props.selectedIndex;
  const productMeta: ProductMetaForPoint = props.data.meta as ProductMetaForPoint;
  const isBonus = productMeta.additionalFreeRewardAmount != undefined && productMeta.additionalFreeRewardAmount > 0;
  return (
    <ProductItemStyle isSelected={isSelected} onClick={props.onClick}>
      <div className={ProductImageBoxStyle}>
        <div
          className={css`
            display: flex;
            justify-content: center;
            width: 64px;
          `}
        >
          <Img
            src={props.data.productImageUrl ? props.data.productImageUrl : IconChurros}
            alt='product_image'
            loader={<LoadingSpinner />}
          />
        </div>

        <div className={TextGroupStyle}>
          <div className={ProductNameStyle}>{props.data.name}</div>
          {isBonus && (
            <div className={BonusLabelStyle}>
              + {productMeta.additionalFreeRewardAmount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}개 보너스
            </div>
          )}
        </div>
      </div>
      <div className={PriceLabelStyle}>{props.data.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 원</div>
      <img src={isSelected ? IconCircleCheckboxOn : IconCircleCheckboxOff} alt='checkbox_off' />
    </ProductItemStyle>
  );
};

const ProductItemStyle = styled.div<{ isSelected: boolean }>`
  width: 100%;
  position: relative;
  border-radius: 8px;
  ${(props) =>
    props.isSelected
      ? 'border: 1px solid var(--light-primary-500, #7800FF);'
      : 'border: 1px solid var(--light-gray-200, #E8E8E8);'};
  ${(props) => (props.isSelected ? 'background: var(--light-primary-100, #F4F0FF);' : 'background: white;')};
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 12px 16px;
  gap: 0px 16px;
  text-align: left;
  font-size: 14px;
  color: #191919;
`;

const ProductImageBoxStyle = css`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  flex: 1;
`;

const TextGroupStyle = css`
  display: flex;
  flex-direction: column;
`;

const ProductNameStyle = css`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
`;

const BonusLabelStyle = css`
  color: var(--primaryPurple-100, #7800ff);
  /* captionDefault */
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
`;

const PriceLabelStyle = css`
  color: var(--black, #000);
  text-align: center;

  /* buttonBig */
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;
