import { css } from '@emotion/css';
import { Button } from '@src/components/common/Button';

interface PaymentButtonProps {
  title?: string;
  isEnable?: boolean;
  onClick?: () => void;
}

export const PaymentButton = ({ title, isEnable = true, onClick }: PaymentButtonProps) => {
  return (
    <div className={PaymentStyle}>
      <Button onClick={onClick} label={title} isEnable={isEnable} />
    </div>
  );
};

const PaymentStyle = css`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 460px;
  height: 72px;
  padding: 8px 16px;
  bottom: 0;
`;
